$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
setTimeout(function() {
  $.ajaxSettings.headers['X-CSRF-TOKEN']
  if (
    typeof $.ajaxSettings === "undefined" || $.ajaxSettings === null ||
    typeof $.ajaxSettings.headers === "undefined" || $.ajaxSettings.headers === null ||
    typeof $.ajaxSettings.headers['X-CSRF-TOKEN'] === "undefined" || $.ajaxSettings.headers['X-CSRF-TOKEN'] === null ||
    $.ajaxSettings.headers['X-CSRF-TOKEN'].length < 1
  ) {
    Sentry.captureMessage("CSRF token missing from page: " + window.Location);
  }


}, 1000);

