var allStickyHeaders = [];
var StickyHeader     = function($wrapper, zIndex, scrollParent, $horizontalScrollDiv) {
  if ($wrapper.closest(".modal").length) {
    // sticky headers in modals are not supported.
    // fixed elements don't work well in modals, since the modals are transformed (stealing the target of "fixed" away from the viewport!)
    return;
  }
  var self                      = this;
  self.resized                  = false;
  self.scroll                   = function() {
    var top           = self.viewportOffset() + "px";
    var currentScroll = self.getScrollTop();
    if (typeof self.$table.offset() === "undefined" || typeof self.$scrollParent.offset() === "undefined") {
      return;
    }
    var scrollTop    = self.$table.offset().top - self.$scrollParent.offset().top + self.$scrollParent.scrollTop();
    var scrollBottom = scrollTop + self.$table.outerHeight() - self.$thead.outerHeight();
    var xScroll      = (self.$horizontalScroll.scrollLeft()) * -1;

    if (self.$scrollParent.is("html")) {
      scrollTop = self.$table.offset().top;
    }

    if (currentScroll > scrollTop && currentScroll < scrollBottom) {
      self.$thead.css({position: "fixed", top: top, "z-index": self.zIndex});
      var vertOffset = 0;
      self.$theadRow.each(function() {
        $(this).css({position: "absolute", left: xScroll + "px", "z-index": self.zIndex, "top": vertOffset + "px"});
        vertOffset += $(this).height();
      });

      self.$theadPlaceholder.show();
      self.resetPlaceholders();
      if (!self.resized) {
        self.resize();
      }
      self.resized = true;

    }
    else {
      self.$thead.css("position", self.theadPos);
      self.$theadRow.css("position", self.theadRowPos);
      self.reset();
      self.$theadPlaceholder.hide();
      self.resized = false;

    }
  };
  self.getScrollTop             = function($element) {
    if (typeof $element === "undefined") {
      $element = self.$scrollParent;
    }
    var output = $element.scrollTop();
    if (output > 0) {
      return output;
    }
    if ($element.is("html")) {
      return self.getScrollTop($("body"));
    }
    if ($element.is("body")) {
      return self.getScrollTop($(document));
    }
    return 0;
  };
  self.reset                    = function() {
    if (!self.floating) {
      return true;
    }
    self.floating = false; // this should be the only place that makes floating false (other than original initialization)
    var cellCount = 0;
    self.$headerCells.each(function() {
      var $thisPlaceholder = self.$placeholderHeaderCells.eq(cellCount);
      var style            = $thisPlaceholder.attr("style");
      if (typeof style === "undefined") {
        style = "";
      }
      $(this).attr("style", style);
      cellCount++;
    });
  };
  self.resetPlaceholders        = function() {
    if (self.floating) {
      return true;
    }
    self.floating = true; // this should be the only place that makes floating true
    var cellCount = 0;
    self.$placeholderHeaderCells.each(function() {
      var $thisHeader = self.$headerCells.eq(cellCount);
      var style       = $thisHeader.attr("style");
      if (typeof style === "undefined") {
        style = "";
      }
      $(this).attr("style", style);
      cellCount++;
    });
  };
  self.resize                   = function() {
    if (!self.floating) {
      return true;
    }
    var cellCount = 0;
    self.$headerCells.each(function() {
      var $thisPlaceholder = self.$placeholderHeaderCells.eq(cellCount);
      var width            = $thisPlaceholder.outerWidth();
      var height           = $thisPlaceholder.outerHeight();
      $(this).css({
        width             : width + "px",
        "min-width"       : width + "px",
        "max-width"       : width + "px",
        height            : height + "px",
        "border-top-width": 0
      });
      cellCount++;
    });
    self.$thead.css({
      width      : self.$wrapper.outerWidth() + "px",
      "min-width": self.$wrapper.outerWidth() + "px",
      "max-width": self.$wrapper.outerWidth() + "px",
      "overflow" : "hidden",
      height     : self.$theadPlaceholder.height() + "px"
    });
  };
  self.getHeaderCellPlaceholder = function($element) {
    var cellCount = 0;
    var result    = false;
    self.$headerCells.each(function() {
      if ($(this).is($element)) {
        result = self.$placeholderHeaderCells.eq(cellCount);
      }
      cellCount++;
    });
    return result;
  };
  self.getScrollParent          = function(element) {
    var style               = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";

    if (style.position === "fixed") {
      return $("html");
    }
    for (var parent = element; (parent = parent.parentElement);) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === "static") {
        continue;
      }
      if (/(auto|scroll)/.test(style.overflow + style.overflowY) && parent.scrollHeight > parent.clientHeight) {
        return $(parent);
      }
    }

    // noinspection JSJQueryEfficiency
    return $("html");
  };
  self.viewportOffset           = function() {
    if (!this.$scrollParent.length) {
      return 0;
    }
    if (this.$scrollParent.is($("html"))) {
      return 0;
    }
    return this.$scrollParent[0].getBoundingClientRect().top;
    // return this.$scrollParent[0].getBoundingClientRect().top + this.$scrollParent.offset().top - this.$wrapper[0].offsetTop;
  };
  self.bindAllParents           = function($element, callable) {
    var $parent = $element.parent();
    if (!$parent.length) {
      return true;
    }
    $parent.scroll(callable);
    self.bindAllParents($parent, callable);
  };
  if (typeof scrollParent === "undefined") {
    scrollParent = "detect";
  }
  self.$wrapper = $wrapper;
  self.floating = false;
  self.zIndex   = zIndex;
  if ($wrapper.is("table")) {
    self.$table = $wrapper;
  }
  else {
    self.$table = $wrapper.find("table:first");
  }

  self.$thead            = self.$table.find("thead");
  self.$theadRow         = self.$thead.find("tr");
  self.theadPos          = self.$thead.css("position");
  self.theadRowPos       = self.$theadRow.css("position");
  self.$headerCells      = self.$thead.find("th, td");
  self.$horizontalScroll = $horizontalScrollDiv;
  if (scrollParent === "detect") {
    self.$scrollParent = self.getScrollParent(self.$wrapper[0]);
  }
  else {
    self.$scrollParent = self.$wrapper.closest(scrollParent);
  }

  self.$theadPlaceholder = self.$thead.clone().css({
    "display"   : "none",
    "visibility": "hidden"
  });

  self.$thead.after(self.$theadPlaceholder);
  self.$placeholderHeaderCells = self.$theadPlaceholder.find("th, td");
  if (self.$scrollParent.is("html")) {
    $(document).scroll(function() {
      self.scroll();
    });
  }
  else {
    self.$scrollParent.scroll(function() {
      self.scroll();
    });
    self.bindAllParents(self.$scrollParent, self.scroll);
  }

  self.$horizontalScroll.scroll(function() {
    self.scroll();
  });

  self.resize();
  $(window).on("resize", function() {
    self.resize();
  });
  allStickyHeaders.push(self);
  self.$wrapper.data("sticky_header", allStickyHeaders.length - 1);
};

function getStickyHeader($wrapper)
{
  if (typeof $wrapper.data('sticky_header') !== 'undefined') {
    return allStickyHeaders[$wrapper.data("sticky_header")];
  }
  return false;
}

function deleteStickyHeader($wrapper)
{
  if (typeof $wrapper.data('sticky_header') !== 'undefined') {
    return allStickyHeaders.splice($wrapper.data("sticky_header"), 1);
  }
  return false;
}

function stickyHeaderResize($wrapper)
{
  var stickyHeader = getStickyHeader($wrapper);
  if (stickyHeader === false) {
    return false;
  }
  stickyHeader.resize();
  return true;
}

