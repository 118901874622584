/*
* Requires:
*
* debounce
* jquery
* bootstrap
* moment.js
* bootstrap datetime picker
*
* */

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
if (typeof Cookies === "undefined") {
  console.log("Include cookies.js before datagrid.js");
}


/* jquery extensions */

$.fn.dataGrid = function(mode, param) {


  var sessionId = $(this).attr('data-grid-session-id');

  if (typeof sessionId !== 'undefined' && sessionId !== false && sessionId.length > 0) {
    return dataGridHelper.getDataGrid(sessionId);
  }


  var $open = this.closest(".data-grid-open");
  var $row;
  if ($open.length) {
    $row = $open.data("row");
    if (!($row instanceof jQuery)) {
      $row = null;
    }
  }
  else {
    $row = this.closest(".data-grid-tbody");
  }
  switch (mode) {
    case "value":
      if (!$row.length) {
        return false;
      }
      return dataGridHelper.getRowDataValue($row, param);
    case "row":
      if (!$row.length) {
        return false;
      }
      return dataGridHelper.getRowData($row);
    case "open":
      if (!$row.length) {
        return false;
      }
      dataGridHelper.getDataGridFromElement($row).openRow($row, param);

      break;
    case "close":
      if ($open.length) {
        dataGrid = dataGridHelper.getDataGrid($open.attr("data-grid-session-id")).closeRow();
      }
      else {
        dataGrid = dataGridHelper.getDataGridFromElement(this).closeRow();
      }

      break;
    default:
      var dataGrid;
      if ($open.length) {
        dataGrid = dataGridHelper.getDataGrid($open.attr("data-grid-session-id"));
      }
      else {
        dataGrid = dataGridHelper.getDataGridFromElement(this);
      }


      if (dataGrid === false || dataGrid === null) {
        return false;
      }
      dataGrid.$selectedRow = $row;
      return dataGrid;

  }


};

var dgUrlParam = {
  remove: function(url, paramName) {
    var reA = new RegExp("&" + paramName + "=[^&#]*", "g");
    var reB = new RegExp("\\?" + paramName + "=[^&#]*&", "g");
    var reC = new RegExp("\\?" + paramName + "=[^&#]*", "g");
    url     = url.replace(reA, "");
    url     = url.replace(reB, "?");
    url     = url.replace(reC, "");

    return url;
  },
  add   : function(url, paramName, value) {
    url = url.replace(/#.*$/g, "");
    url = dgUrlParam.remove(url, paramName);
    if (url.indexOf("?") !== -1) {
      var count = (url.match(/\?/g) || []).length;
      if (url.substr(-1) === "?" && count === 1) {
        url += paramName + "=";
      }
      else {
        url += "&" + paramName + "=";
      }
    }
    else {
      url += "?" + paramName + "=";
    }
    url += value;

    return url;
  }
};

var allDataGrids = [];

var DataGrid = function($dataGridElem) {
  dataGridHelper.checkDataGrids();
  var dataGrid = this;

  this.$selectedRow               = null;
  this.rowOpen                    = false;
  this.rowOpenAjaxFinished        = false;
  this.$rowThatIsOpen             = null;
  this.$lastCheckedCheckbox       = $();
  this.additionalBindingFunctions = [];
  this.disablePushState           = false; // used internally, cannot be used to disable the feature
  this.rowToOpenAfterLoading      = null;
  this.recentlyOpenedRow          = null;
  this.onDraw                     = function(callable) {
    if (typeof callable !== "function") {
      throw new Error("Must pass a function to the onDraw() method.");
    }
    this.onDrawFunctions.push(callable);

    return this;
  };
  this.horizontalScrollVisible    = function() {
    var $horizontalScrollElem = dataGrid.wrapper.find(".data-grid-horizontal-scroll:first");
    if (!$horizontalScrollElem.length) {
      return false;
    }
    return ($horizontalScrollElem[0].scrollWidth > $horizontalScrollElem[0].clientWidth);
  };
  this.forgetColumnWidths         = function() {
    dataGrid.wrapper.find(".data-grid-header-cell").each(function() {
      var fieldName  = $(this).attr("data-field");
      var cookieName = dataGrid.name + "_" + "col_width_" + fieldName;
      Cookies.remove(cookieName);
    });
    dataGrid.wrapper.find(".data-grid-tbody.dg-is-not-row-header:first").find("td.data-grid-data-single").each(function() {
      var fieldName  = $(this).attr("data-grid-field");
      var cookieName = dataGrid.name + "_" + "col_width_" + fieldName;
      Cookies.remove(cookieName);
    });
  };
  this.getRowCount                = function() {
    return dataGrid.wrapper.find(".data-grid-tbody").length;
  };
  this.onDrawFunctions            = [];
  this.onOpen                     = function(callable) {
    if (typeof callable !== "function") {
      throw new Error("Must pass a function to the onOpen() method.");
    }
    this.onOpenFunctions.push(callable);
    if (this.rowOpen) {
      callable(dataGridHelper.getRowData(dataGrid.$open.data("row")));
    }
    return this;
  };
  this.onOpenFunctions            = [];
  this.onAfterOpenAjax            = function(callable) {
    if (typeof callable !== "function") {
      throw new Error("Must pass a function to the onAfterOpenAjax() method.");
    }
    this.onAfterOpenAjaxFunctions.push(callable);
    if (this.rowOpenAjaxFinished) {
      callable(this.lastAjaxResponse, dataGridHelper.getRowData(dataGrid.$open.data("row")));
    }
    return this;
  };
  this.onAfterOpenAjaxFunctions   = [];
  this.onClose                    = function(callable) {
    if (typeof callable !== "function") {
      throw new Error("Must pass a function to the onClose() method.");
    }
    this.onCloseFunctions.push(callable);
    return this;
  };
  this.onCloseFunctions           = [];
  this.beforeSearch               = function() {
    // if you return "false", the search is stopped
    return true;
  };
  this.afterSearch                = function() {
    // if you return "false", the table is not updated
    return true;
  };
  this.toggleAllChildRows         = function() {
    this.hideChildrenRow = !this.hideChildrenRow;
    this.wrapper.find(".data-grid-tbody").each(function() {
      if (dataGrid.hideChildrenRow) {
        dataGridHelper.hideChildRow($(this));
      }
      else {
        dataGridHelper.showChildRow($(this));
      }
    });
  };
  this.afterDraw                  = function() {
    if (dataGrid.recentlyOpenedRow !== null) {
      dataGrid.wrapper.find("tbody[data-row-id='" + dataGrid.recentlyOpenedRow + "']").addClass("data-grid-row-flash");
    }
    $("[data-grid-button-search='yes']").off("click", dataGridHelper.searchButtonClick).click(dataGridHelper.searchButtonClick);
    this.wrapper.find(".data-grid-checkbox-row:first").change();
    if (typeof getStickyHeader === 'function') {
      var stickyHeader = getStickyHeader(this.wrapper);
      if (stickyHeader !== false) {
        stickyHeader.scroll();
      }
    }
    var $advancedSearchesCount = $(".data-grid-advanced-number-search[data-grid-session-id='" + this.id + "']");
    if ($advancedSearchesCount.length) {
      if ($advancedSearchesCount.attr("data-grid-parenthesis") === "yes") {
        $advancedSearchesCount.html("(" + this.advanacedSearchCount + ")");
      }
      else {
        $advancedSearchesCount.html(this.advanacedSearchCount);
      }
      if ($advancedSearchesCount.attr("data-grid-hide-on-zero") === "yes") {
        if (this.advanacedSearchCount === 0 || this.advanacedSearchCount === "0") {
          $advancedSearchesCount.hide();
        }
        else {
          $advancedSearchesCount.show();
        }
      }
    }


    this.onDrawFunctions.forEach(function(callable) {
      callable(this);
    });

    if (this.rowToOpenAfterLoading !== null) {
      var $row = this.getRowFromId(this.rowToOpenAfterLoading[0]);
      if ($row.length) {
        this.openRow($row, this.rowToOpenAfterLoading[1], this.rowToOpenAfterLoading[2]);
      }
      this.rowToOpenAfterLoading = null;
    }

    // If the width is too wide to fit on the screen, drop the column width cookies so that it doesn't look broken...
    if (dataGrid.horizontalScrollVisible()) {
      this.forgetColumnWidths();
    }

    return true;
  };

  this.initVars           = function($dataGridElem) {
    this.lastAjaxResponse        = null;
    this.id                      = $dataGridElem.attr("data-grid-session-id");
    this.name                    = $dataGridElem.attr("data-grid-name");
    this.currentPage             = $dataGridElem.attr("data-grid-current-page");
    this.totalPages              = $dataGridElem.attr("data-grid-total-pages");
    this.totalRecords            = $dataGridElem.attr("data-grid-total-records");
    this.totalRecordsFormatted   = $dataGridElem.attr("data-grid-total-records-formatted");
    this.advanacedSearchCount    = $dataGridElem.attr("data-grid-advanced-search-count");
    this.pageName                = $dataGridElem.attr("data-grid-page-name") || "page";
    this.pageSizeName            = $dataGridElem.attr("data-grid-page-size-name") || "page_size";
    this.sessionIdVarName        = $dataGridElem.attr("data-grid-session-id-var-name") || "page";
    this.hiddenVarsName          = $dataGridElem.attr("data-grid-hidden-vars-name");
    this.scrollAboveGrid         = $dataGridElem.attr("data-grid-scroll-above-grid") === "yes";
    this.searchActive            = $dataGridElem.attr("data-grid-search-active") === "yes";
    this.rowsArePrerendered      = $dataGridElem.attr("data-grid-rows-are-prerendered") === "yes";
    this.scrollAboveGridAtStart  = $dataGridElem.attr("data-grid-scroll-above-grid-at-start") === "yes";
    this.scrollAboveGridAnimate  = $dataGridElem.attr("data-grid-scroll-above-grid-animate") === "yes";
    this.scrollAboveGridPixels   = $dataGridElem.attr("data-grid-scroll-above-grid-pixels");
    this.stickyHeader            = $dataGridElem.attr("data-grid-sticky-table-header") === "yes";
    this.scrollParentSelector    = $dataGridElem.attr("data-grid-scroll-parent-selector");
    this.stickyHeaderZIndex      = $dataGridElem.attr("data-grid-sticky-header-z-index");
    this.openOnClick             = $dataGridElem.attr("data-grid-open-on-click") === "yes";
    this.checkOnClick            = $dataGridElem.attr("data-grid-check-on-click") === "yes";
    this.ajaxOpenAlways          = $dataGridElem.attr("data-grid-ajax-open-always") === "yes";
    this.openDelay               = $dataGridElem.attr("data-grid-open-delay") === "yes";
    this.openFirstRow            = $dataGridElem.attr("data-grid-open-first-row") === "yes";
    this.openRowModeUpdateUrl    = $dataGridElem.attr("data-grid-open-row-mode-update-url") === "yes";
    this.openRowModeUrlParamName = $dataGridElem.attr("data-grid-open-row-mode-url-param-name");
    this.openRowMode             = $dataGridElem.attr("data-grid-open-row-mode");
    this.hideGearErrorsOnRowOpen = $dataGridElem.attr("data-grid-hide-gear-errors-on-row-open") === "yes";
    this.openHide                = $dataGridElem.attr("data-grid-open-hide");
    this.moveSettingsDialogTo    = $dataGridElem.attr("data-grid-move-settings-dialog-to");
    this.allowOpenWhileLoading   = $dataGridElem.attr("data-grid-allow-open-while-loading") === "yes";
    this.countCapReached         = $dataGridElem.attr("data-grid-count-cap-reached") === "yes";
    this.rowOpenUrlRequireGrid   = $dataGridElem.attr("data-grid-row-open-url-require-grid") === "yes";
    this.rowOpenUrlRowIdName     = $dataGridElem.attr("data-grid-row-open-url-row-id-name");
    this.openRowClearUrlParams   = $dataGridElem.attr("data-grid-open-row-clear-url-params").split(",").filter(function(str) {
      return str.trim() !== "";
    });
    this.closeRowClearUrlParams  = $dataGridElem.attr("data-grid-close-row-clear-url-params").split(",").filter(function(str) {
      return str.trim() !== "";
    });
    this.openRowForwardUrlParams = $dataGridElem.attr("data-grid-open-row-forward-url-params").split(",").filter(function(str) {
      return str.trim() !== "";
    });
    this.rowOpenUrlGridParamName = $dataGridElem.attr("data-grid-row-open-url-grid-id-name");
    this.rememberScroll          = 0;
    this.currentlyLoading        = false;
    this.$rememberScrollDiv      = null;
    this.defaultFontSize         = $dataGridElem.attr("data-grid-default-font-size");
    this.defaultSpacing          = $dataGridElem.attr("data-grid-default-spacing");
    if (typeof this.defaulthideChildrenRow === "undefined") {
      this.defaulthideChildrenRow = $dataGridElem.attr("data-grid-hide-children-row") === "yes";
      this.hideChildrenRow        = this.defaulthideChildrenRow;
    }


    this.ajaxUrl              = $dataGridElem.attr("data-ajax-url");
    this.prerenderedAjaxUrl   = $dataGridElem.attr("data-prerendered-ajax-url");
    this.ajaxInputUrl         = $dataGridElem.attr("data-ajax-input-url");
    this.csvUrl               = $dataGridElem.attr("data-csv-url");
    this.ajaxOpenUrl          = $dataGridElem.attr("data-ajax-open-url");
    this.wrapper              = $dataGridElem;
    this.$open                = $(".data-grid-open[data-grid-session-id='" + dataGrid.id + "']");
    this.settingsModal        = $dataGridElem.find(".data-grid-settings-modal");
    this.updateUrlEnabled     = $dataGridElem.attr("data-grid-update-url") === "yes";
    this.defaultToLastPage    = $dataGridElem.attr("data-grid-default-to-last-page") === "yes";
    this.jsDefaultToFormatted = $dataGridElem.attr("data-grid-js-default-to-formatted") === "yes";
    if (typeof this.searchAjaxRequests === "undefined") {
      this.searchAjaxRequests = [];
    }
    if (typeof this.openAjaxRequests === "undefined") {
      this.openAjaxRequests = [];
    }
    if (typeof this.moveSettingsDialogTo !== "undefined" && this.moveSettingsDialogTo.length > 0) {
      var $newLoc        = $(this.moveSettingsDialogTo);
      var $alreadyExists = $newLoc.find("#" + this.settingsModal.attr("id"));
      if ($alreadyExists.length) {
        this.settingsModal.remove();
        this.settingsModal = $alreadyExists;
      }
      else {
        $(this.moveSettingsDialogTo).append(this.settingsModal.detach());
      }

    }
  };
  this.closeSettingsModal = function() {
    this.settingsModal.modal("hide");
  };
  this.openSettingsModal  = function() {
    this.settingsModal.attr("data-grid-session-id", this.id);
    this.settingsModal.modal("show");
  };

  this.addSearch = function($target, field, operation, duplicatesAllowed, callback, replaceInstead, doNotMarkAsAdded) {

    operation                   = operation || "=";
    duplicatesAllowed           = duplicatesAllowed || false;
    replaceInstead              = replaceInstead || false;
    doNotMarkAsAdded            = doNotMarkAsAdded || false;
    var currentValue            = $target.find(".data-grid-search[name='" + field + "'][data-grid-session-id='" + this.id + "'").val();
    var data                    = {field: field, operation: operation, currentValue: currentValue};
    data[this.sessionIdVarName] = this.id;
    if (this.ajaxInputUrl.length < 1) {
      return false;
    }
    $.ajax({
      url     : this.ajaxInputUrl,
      type    : "POST",
      data    : data,
      success : function(response) {
        var $html          = $(response + "<br>");
        var $searchInput   = $html.filter(".data-grid-search");
        var $alreadyExists = $("[data-grid-session-id='" + dataGrid.id + "'][name='" + $searchInput.attr("name") + "']");

        if ($alreadyExists.length && !duplicatesAllowed && !replaceInstead) {
          $alreadyExists.focus();
          if (typeof callback === "function") {
            callback($alreadyExists);
          }
          return true;
        }
        if (replaceInstead) {
          $target.html($html);
        }
        else {
          $target.append($html);
        }

        if ($.isFunction($.fn.tooltip)) {
          $html.find("[data-toggle='tooltip']").tooltip({boundary: 'window'});
        }

        if ($searchInput.hasClass("selectpicker")) {
          $searchInput.selectpicker();
        }
        $html.focus();
        if (!doNotMarkAsAdded) {
          $html.addClass("data-grid-added-search");
        }
        $html.attr("data-grid-session-id", dataGrid.id);
        dataGridHelper.bindSearchElements(dataGrid, $searchInput);
        if (typeof callback === "function") {
          callback($searchInput);
        }
      }, error: function(response) {
        if (response.statusText !== "abort") {
          console.log("Ajax error, response:");
          console.log(response);
        }
        if (typeof response.responseJSON !==
            "undefined" &&
            typeof response.responseJSON.message !==
            "undefined" &&
            response.responseJSON.message.length >
            0 &&
            typeof toast ===
            "function") {
          toast({message: response.responseJSON.message, type: "danger"});
        }
        else {
          if (typeof response.responseText !==
              "undefined" &&
              response.responseText.length >
              0 &&
              typeof toast ===
              "function") {
            toast({message: response.responseText, type: "danger"});
          }
        }
      }
    });
  };

  this.initVars($dataGridElem);

  /*
  *
  * Search function
  *
  * pageNum less than zero means "keep previous page number".
  * */
  this.search = function(pageNum, maintainScroll) {
    maintainScroll = maintainScroll || false;
    if (pageNum < 0) {
      pageNum = this.currentPage;
    }
    var defaultPage = 1;
    if (this.defaultToLastPage) {
      defaultPage = parseInt(this.totalPages);
    }
    pageNum  = pageNum || defaultPage;
    var data = this.gatherVars();

    data[this.sessionIdVarName] = this.id;
    if (parseInt(pageNum) !== defaultPage) {
      data[this.pageName] = pageNum;
    }

    this.updateUrl(data);
    var ajaxUrlToUse = this.ajaxUrl;
    if (this.rowsArePrerendered) {
      ajaxUrlToUse = this.prerenderedAjaxUrl;
    }

    if (!this.beforeSearch(data)) {
      return true;
    }
    if (ajaxUrlToUse < 1) {
      return false;
    }

    this.searchAjaxRequests.forEach(function(thisAjaxRequest) {
      thisAjaxRequest.abort();
    });
    this.searchAjaxRequests = [];

    dataGrid.wrapper.find(".data-grid-corner-refresh").hide();
    dataGrid.wrapper.find(".data-grid-corner-display-settings").css({visibility: "hidden"});
    dataGrid.wrapper.find(".data-grid-download-csv").css({visibility: "hidden"});
    dataGrid.wrapper.find(".data-grid-corner-loader").show();
    dataGrid.wrapper.find(".data-grid-checkbox-row").prop("disabled", true);
    dataGrid.wrapper.find(".data-grid-check-all").prop("disabled", true);
    var $floatLoader = $(".data-grid-float-loader[data-grid-session-id='" + dataGrid.id + "']");
    $floatLoader.show();

    if (!maintainScroll && dataGrid.scrollAboveGridAtStart) {
      dataGrid.ensureTopOfGridIsVisible();
    }

    var $allCheckboxes = dataGrid.wrapper.find(".data-grid-checkbox-row");
    $allCheckboxes.prop("checked", false);
    $($allCheckboxes[0]).change();
    // don't allow opening of any modals that are about to get reset!
    dataGrid.wrapper.find(".modal").each(function() {
      $(this).modal("hide");
    });
    dataGrid.wrapper.find("[data-toggle='modal']").css({"visibility": "hidden"});
    dataGrid.currentlyLoading = true;
    var ajaxRequest           = $.ajax({
      url     : ajaxUrlToUse,
      type    : "POST",
      data    : data,
      success : function(response) {
        dataGrid.currentlyLoading = false;
        var dataGridHTML          = response.datagrid;
        var sortButtons           = response.sortButtons;
        var fieldName;
        for (fieldName in sortButtons) {
          if (sortButtons.hasOwnProperty(fieldName)) {
            //return " data-grid-button-sort='yes' data-grid-field='" . $sort . "' ";
            var $newSortButton = $(sortButtons[fieldName]);
            $newSortButton.click(dataGridHelper.sortButtonClick);
            $("[data-grid-button-sort='yes'][data-grid-field='" + fieldName + "']").find(".data-grid-sort").replaceWith($newSortButton);
          }
        }
        if (!dataGrid.afterSearch(dataGridHTML, data)) {
          $floatLoader.hide();
          return true;
        }
        var $newGrid = $(dataGridHTML);
        var $oldGrid = $(".data-grid-wrapper[data-grid-session-id='" + dataGrid.id + "']");
        if (typeof $oldGrid.data("sticky_header") !== "undefined") {
          deleteStickyHeader($oldGrid);
        }
        $oldGrid.replaceWith($newGrid);
        var existingDataGridObj = dataGrid;
        // 2019.08.12-TSO: Why was I getting the datagrid object from the ID, when we already have it?
        // var existingDataGridObj = getDataGrid(dataGrid.id, "id");

        if (existingDataGridObj === false) {
          var newDataGridObj = new DataGrid($newGrid);
          newDataGridObj.ensureSingleFloatingLoader();
          if (!dataGrid.scrollAboveGridAtStart) {
            newDataGridObj.ensureTopOfGridIsVisible();
          }
        }
        else {
          existingDataGridObj.initVars($newGrid);
          existingDataGridObj.initBindings($newGrid);
          existingDataGridObj.ensureSingleFloatingLoader();
          if (!dataGrid.scrollAboveGridAtStart) {
            existingDataGridObj.ensureTopOfGridIsVisible();
          }
        }
        dataGrid.wrapper.find(".data-grid-corner-loader").hide();
        dataGrid.wrapper.find(".data-grid-corner-refresh").show();
        dataGrid.wrapper.find(".data-grid-corner-display-settings").css({visibility: "visible"});
        dataGrid.wrapper.find(".data-grid-download-csv").css({visibility: "visible"});
        dataGrid.wrapper.find(".data-grid-checkbox-row").prop("disabled", false);
        dataGrid.wrapper.find(".data-grid-check-all").prop("disabled", false);
        dataGrid.afterDraw();
        if ($.isFunction($.fn.tooltip)) {
          $newGrid.find("[data-toggle='tooltip']").tooltip({boundary: 'window'});
        }
        $floatLoader.hide();
      }, error: function(response) {
        dataGrid.currentlyLoading = false;
        if (response.statusText !== "abort") {
          console.log("Ajax error, response:");
          console.log(response);
        }
        if (typeof response.responseJSON !==
            "undefined" &&
            typeof response.responseJSON.message !==
            "undefined" &&
            response.responseJSON.message.length >
            0 &&
            typeof toast ===
            "function") {
          toast({message: response.responseJSON.message, type: "danger"});
        }
        else {
          if (typeof response.responseText !==
              "undefined" &&
              response.responseText.length >
              0 &&
              typeof toast ===
              "function") {
            toast({message: response.responseText, type: "danger"});
          }
        }
        $floatLoader.hide();
        dataGrid.wrapper.find(".data-grid-corner-loader").hide();
        dataGrid.wrapper.find(".data-grid-corner-refresh").show();
        dataGrid.wrapper.find(".data-grid-corner-display-settings").css({visibility: "visible"});
        dataGrid.wrapper.find(".data-grid-download-csv").css({visibility: "visible"});
        dataGrid.wrapper.find(".data-grid-checkbox-row").prop("disabled", false);
        dataGrid.wrapper.find(".data-grid-check-all").prop("disabled", false);
      }
    });

    this.searchAjaxRequests.push(ajaxRequest);
    return true;
  };
  /*
  *
  * End of search function
  *
  * */

  this.gatherVarAppend = function(output, field, value, isSortOrSortOrder) {
    // Sorts are never arrays (that makes no sense, sorting by the same field multiple times would be pointless)
    if (typeof output[field] === "undefined" || isSortOrSortOrder) {
      output[field] = value;
      return;
    }

    if (Array.isArray(output[field])) {
      output[field].push(value);
      return;
    }

    output[field] = [output[field], value];
  };

  this.gatherVars = function() {
    var output          = {};
    var addIfNonDefault = {sort: {}, search: {}};
    var nonDefault      = {sort: false, search: false};
    var that            = this;


    // ------------
    // ------------
    // ------------
    // ------------
    // ------------

    $(".data-grid-input[data-grid-session-id='" + this.id + "']").each(function() {
      var dataGridSessionId = $(this).attr("data-grid-session-id");
      var isSort            = $(this).hasClass("data-grid-input-sort");
      var isSortOrSortOrder = $(this).hasClass("data-grid-input-sort") || $(this).hasClass("data-grid-input-sort-order");
      var sortOrSearch      = "search";
      if (isSort) {
        sortOrSearch = "sort";
      }
      var field     = $(this).attr("name");
      var value     = "";
      var inputType = $(this).attr("type");
      if ((inputType !== "checkbox" && inputType !== "radio") || $(this).is(":checked")) {
        value = $(this).val();
      }


      if (typeof value === "undefined" || value === null) {
        value = "";
      }

      if ($(this).hasClass("data-grid-hidden-checkbox-input") &&
          $(".data-grid-input[type='checkbox'][data-grid-session-id='" + dataGridSessionId + "'][name='" + field + "']:checked").length) {
        // Ignore the hidden checkbox input if the associated input is checked
        return true;
      }
      if ($(this).is("input[type='checkbox']:not(:checked)") &&
          $(".data-grid-input.data-grid-hidden-checkbox-input[type='hidden'][data-grid-session-id='" +
            dataGridSessionId + "'][name='" + field + "']").length) {
        // Ignore the unchecked checkbox input if the associated hidden input is present
        return true;
      }


      var $shortcutButtons = $("[data-grid-button-search='yes'][data-grid-field='" + field + "']").removeClass("active");
      $shortcutButtons.filter("[data-grid-search-value='" + dg_escapeStr(value) + "']").addClass("active");
      var defaultValue = $(this).attr("data-grid-default-value");
      if (value.length > 0 || $(this).attr("data-has-default") === "yes") {
        if (typeof defaultValue !== "undefined" && defaultValue !== null && defaultValue.length > 0 &&
            defaultValue.toLowerCase() === value.toLowerCase()) {
          addIfNonDefault[sortOrSearch][field] = value;

          return true;
        }

        if (isSort && $(this).attr("data-grid-skip-url") !== "yes"
            && [that.sessionIdVarName, that.pageSizeName, that.hiddenVarsName, "additionalSearch"].indexOf(field) < 0) {
          nonDefault[sortOrSearch] = true;
        }
        that.gatherVarAppend(output, field, value, isSortOrSortOrder);

        return true; // continue
      }

      if ($(this).hasClass("data-grid-input-sort") && typeof defaultValue !== "undefined" && defaultValue !== null && defaultValue.length > 0) {
        var $otherSortInputs = $(".data-grid-input[data-grid-session-id='" + that.id + "'][name=" + field + "]");

        var someValueExists = false;
        $otherSortInputs.each(function() {
          var thisValue = $(this).val();
          if (typeof thisValue !== "undefined" && thisValue !== null && thisValue.length > 0) {
            someValueExists = true;
          }
        });
        if (!someValueExists) {
          nonDefault[sortOrSearch] = true; // default sort is turned off? that means this is not a default sort.
        }
      }

    });
    // ------------
    // ------------
    // ------------
    // ------------
    // ------------
    // ------------
    // ------------
    // ------------

    if (nonDefault.sort) {
      for (var field in addIfNonDefault.sort) {
        if (addIfNonDefault.sort.hasOwnProperty(field)) {
          that.gatherVarAppend(output, field, addIfNonDefault.sort[field], true);
        }
      }
    }
    if (nonDefault.search) {
      for (field in addIfNonDefault.search) {
        if (addIfNonDefault.search.hasOwnProperty(field)) {
          that.gatherVarAppend(output, field, addIfNonDefault.search[field], false);
        }
      }
    }
    output[this.pageSizeName] = $(".data-grid-page-size-select[data-grid-session-id='" + this.id + "']").val();
    if (typeof this.addAdditionalSearch === "function") {
      output["additionalSearch"] = this.addAdditionalSearch();
    }
    if (this.hideChildrenRow !== this.defaulthideChildrenRow) {
      output["inverseChildHidden"] = "yes";
    }
    return output;
  };

  // Only scroll up, never down....
  this.ensureTopOfGridIsVisible   = function() {
    if (!this.scrollAboveGrid) {
      return true;
    }
    var $scrollParent = dataGridHelper.getScrollParent(this.wrapper[0]);
    if (!$scrollParent.length) {
      return true;
    }

    var topElement = false;
    if ($scrollParent.is("html")) {
      topElement = true;
      if (parseInt($scrollParent.scrollTop()) === 0) {
        $scrollParent = $("body");
      }
    }

    var topOfGrid = this.wrapper.offset().top - $scrollParent.offset().top;

    if (!topElement) {
      topOfGrid += $scrollParent.scrollTop();
    }

    var topOfView = $scrollParent.scrollTop();


    if (topOfView > topOfGrid) {
      var newScroll = topOfGrid - this.scrollAboveGridPixels;
      if (this.scrollAboveGridAnimate) {
        $scrollParent.stop().animate({scrollTop: newScroll}, 500, 'swing');
      }
      else {
        $scrollParent.scrollTop(newScroll);
      }

    }
  };
  this.ensureSingleFloatingLoader = function() {
    var $floaterAlreadySelected = $(".data-grid-float-loader[data-grid-session-id='" + dataGrid.id + "'][data-grid-current-loader='yes']");
    var $floatLoaders           = $(".data-grid-float-loader[data-grid-session-id='" + dataGrid.id + "']");
    if ($floaterAlreadySelected.length) {
      $floatLoaders.not($floaterAlreadySelected).remove();
      return $floaterAlreadySelected;
    }

    var $return = null;
    var first   = true;
    $floatLoaders.each(function() {
      if (first) {
        $return = $(this).attr("data-grid-current-loader", "yes");
        first   = false;
        return true;
      }
      $(this).remove();
    });
    return $return;
  };
  this.pullFloatingLoader         = function() {
    return this.ensureSingleFloatingLoader().detach();
  };
  this.removeFloatLoader          = function() {
    $(".data-grid-float-loader[data-grid-session-id='" + dataGrid.id + "']").remove();
  };
  this.resetInput                 = function($input) {
    $input.each(function() {

      if ($(this).hasClass("data-grid-hidden-checkbox-input")) {
        return true;
      }

      var inputType    = $(this).attr("type");
      var defaultValue = $(this).attr("data-grid-default-value");

      if (typeof defaultValue === "undefined" || defaultValue.length < 1) {
        defaultValue = "";
      }
      if ((inputType === "checkbox" || inputType === "radio")) {
        if (defaultValue === $(this).val()) {
          $(this).prop("checked", true).change();
        }
        else {
          $(this).prop("checked", false).change();
        }
        return true;
      }

      $(this).val(defaultValue).change().keyup();
    });
  };
  this.reset                      = function() {
    $(".data-grid-input[data-grid-session-id='" + this.id + "']").each(function() {
      dataGrid.resetInput($(this));
    });
    $(".data-grid-added-search[data-grid-session-id='" + this.id + "']").each(function() {
      if ($(this).hasClass("selectpicker")) {
        var $wrapper = $(this).closest(".bootstrap-select");
        if ($wrapper.length) {
          $wrapper.remove();
          return true;
        }
      }
      $(this).remove();
    });
    return this;
  };
  this.updateUrl                  = function(data) {

    if (this.disablePushState) {
      return true;
    }
    if (!this.updateUrlEnabled) {
      return true;
    }

    var skipVars = [this.sessionIdVarName, this.pageSizeName, this.hiddenVarsName, "additionalSearch"];

    $("input[data-grid-skip-url='yes'][data-grid-session-id='" + this.id + "']").each(function() {
      skipVars.push($(this).attr("name"));
    });

    var originalUrl = window.location.href.split("?")[0];
    var url         = originalUrl + "?";
    var first       = true;
    var property;
    for (property in data) {
      if (data.hasOwnProperty(property)) {
        if (skipVars.indexOf(property) > -1) {
          continue;
        }


        if (Array.isArray(data[property])) {
          data[property].forEach(function(singleValue) {
            if (!first) {
              url += "&";
            }
            first = false;
            url += encodeURIComponent(property) + "[]=" + encodeURIComponent(singleValue);
          });
        }
        else {

          if (!first) {
            url += "&";
          }
          first = false;

          url += encodeURIComponent(property) + "=" + encodeURIComponent(data[property]);
        }
      }
    }
    // Are there no url query params?
    if (first) {
      // No url query params... don't need the trailing "?"...
      url = originalUrl;
    }

    window.history.pushState({dataGrid: "yes", mode: "search"}, document.title, url);
    // use this to avoid affecting browser history
    // window.history.replaceState({}, document.title, url);

    return true;
  };
  this.initBindingsInRows         = function($wrapper) {
    dataGridHelper.bindCheckboxes(this, $wrapper);
    dataGridHelper.bindChildRowControlElements(this, $wrapper);
    dataGridHelper.bindLinkElements(this, $wrapper);
    dataGridHelper.bindGears($wrapper);
    dataGridHelper.bindAdditional(this, $wrapper);
    dataGridHelper.bindRows(this, $wrapper);
  };
  this.initBindings               = function($dataGridElem) {

    dataGridHelper.bindPaginationAjax(this, $dataGridElem);
    dataGridHelper.bindPageSizeSelect(this, $dataGridElem);
    dataGridHelper.bindSortElements(this, $dataGridElem);
    dataGridHelper.bindRefreshElements(this, $dataGridElem);

    dataGridHelper.bindSearchElements(this);
    dataGridHelper.bindAddSearchButtons(this);
    dataGridHelper.bindSearchForm(this);
    dataGridHelper.bindSettingsButton(this);


    dataGridHelper.bindCookieButtons(this, $dataGridElem);
    if (this.stickyHeader && typeof StickyHeader === "function") {
      new StickyHeader($dataGridElem, this.stickyHeaderZIndex, this.scrollParentSelector, this.wrapper.find(".data-grid-horizontal-scroll"));
    }

    this.initBindingsInRows($dataGridElem);
  };
  this.row                        = function(row_id) {
    if (typeof row_id === "undefined") {
      return dataGridHelper.getRowData(this.$selectedRow);
    }
    return dataGridHelper.getRowData(this.wrapper.find("tbody[data-row-id='" + row_id + "']"));
  };
  this.rowElem                    = function(row_id) {
    return this.wrapper.find("tbody[data-row-id='" + row_id + "']");
  };
  this.data                       = function() {
    if (this.$selectedRow === null) {
      return false;
    }
    return dataGridHelper.getRowData(this.$selectedRow);
  };
  this.value                      = function(value) {
    if (this.$selectedRow === null) {
      return false;
    }
    return dataGridHelper.getRowDataValue(this.$selectedRow, value);
  };
  this.rowValue                   = function(row_id, value) {
    return dataGridHelper.getRowDataValue(this.wrapper.find("tbody[data-row-id='" + row_id + "']"), value);
  };
  this.getRowNum                  = function(rowNum, onlyChecked, formatted) {
    onlyChecked = onlyChecked || false;
    return dataGridHelper.getRowData(this.getRowNumElem(rowNum, onlyChecked), formatted);
  };
  this.getRowNumElem              = function(rowNum, onlyChecked) {
    onlyChecked = onlyChecked || false;
    rowNum--;
    if (onlyChecked) {
      var $checkbox = this.wrapper.find("tbody.data-grid-tbody.dg-is-not-row-header input.data-grid-checkbox-row:checked:eq(" + rowNum + ")");
      return $checkbox.closest("tbody.data-grid-tbody");
    }
    else {
      return this.wrapper.find("tbody.data-grid-tbody.dg-is-not-row-header:eq(" + rowNum + ")");
    }

  };
  this.getRowNumValue             = function(rowNum, value) {
    rowNum--;
    return dataGridHelper.getRowDataValue(this.wrapper.find("tbody.data-grid-tbody.dg-is-not-row-header:eq(" + rowNum + ")"), value);
  };
  this.getRows                    = function() {
    var $rows  = this.wrapper.find("tbody.data-grid-tbody.dg-is-not-row-header");
    var output = [];
    $rows.each(function() {
      output.push(dataGridHelper.getRowData($(this)));
    });

    return output;

  };
  this.addRowDetail               = function(rowID, detail, rowClass, clearPreviousDetails) {
    if (typeof clearPreviousDetails !== 'boolean') {
      if (clearPreviousDetails === "false") {
        clearPreviousDetails = false;
      }
      else {
        clearPreviousDetails = true;
      }
    }
    if (typeof rowClass !== "string") {
      rowClass = "";
    }
    var $row = this.wrapper.find("tbody[data-row-id='" + rowID + "']");
    if (!$row.length) {
      return true;
    }
    var colNum = 0;
    $row.find("tr:first").find("td,th").each(function() {
      if ($(this).hasClass("data-grid-expand-with-child")) {
        return true;
      }
      if ($(this).css("display") === "none") {
        return true;
      }
      var colspan = $(this).attr("colspan");
      if (typeof colspan !== "undefined" && colspan > 0) {
        colNum += parseInt(colspan);
      }
      else {
        colNum += 1;
      }
    });
    var numToRemove = 0;
    if (clearPreviousDetails) {
      var $previousDetails = $row.find(".data-grid-row-detail");
      numToRemove          = $row.length;
      $previousDetails.remove();

    }
    $row.append("<tr class='data-grid-row-detail " +
                // rowClass +
                "'><td colspan='" +
                colNum +
                "'><div style='width: 100%;' class='" + rowClass + "'>" +
                detail +
                "</div></td></tr>");
    dataGridHelper.updateTbodyLastTr($row);
    $row.find(".data-grid-expand-with-child").each(function() {
      var currentRowSpan = $(this).attr("rowspan");
      $(this).attr("rowspan", currentRowSpan + 1 - numToRemove);
    });


  };
  this.removeRowDetail            = function(rowID) {
    var $row      = this.wrapper.find("tbody[data-row-id='" + rowID + "']");
    var $toRemove = $row.find(".data-grid-row-detail");
    if (!$toRemove.length) {
      return true;
    }
    $toRemove.remove();
    dataGridHelper.updateTbodyLastTr($row);
  };

  this.replaceRow = function(rowID, content) {
    var $row = this.wrapper.find(".data-grid-tbody[data-row-id='" + rowID + "']");
    if (!$row.length) {
      return false;
    }
    var dataGrid    = $row.dataGrid();
    var openedRowId = dataGrid.getOpenedRowId();

    $newRow = $(content);

    $row.replaceWith($newRow);

    if (openedRowId !== null) {
      dataGrid.updateOpenedRow(openedRowId);
    }

    if (typeof gearJS !== "undefined") {
      gearJS.auto($newRow);
    }
    this.initBindingsInRows($newRow);

    $newRow.find(".data-grid-checkbox-row").trigger("change").prop("disabled", false);
    return $newRow;
  };

  this.checkedRows = function() {
    var output = [];
    this.wrapper.find(".data-grid-checkbox-row:checked").each(function() {
      output.push($(this).attr("data-row-id"));
    });
    return output;
  };

  this.checkedRowsWithValue = function(field, value) {
    var output = [];
    this.wrapper.find(".data-grid-checkbox-row:checked").each(function() {
      if ($(this).dataGrid().value(field) === value) {
        output.push($(this).attr("data-row-id"));
      }
    });
    return output;
  };

  this.removeUrlParam = function(url, paramName) {
    var reA = new RegExp("&" + paramName + "=[^&#]*", "g");
    var reB = new RegExp("\?" + paramName + "=[^&#]*&", "g");
    var reC = new RegExp("\?" + paramName + "=[^&#]*", "g");
    url     = url.replace(reA, "");
    url     = url.replace(reB, "?");
    url     = url.replace(reC, "");

    return url;
  };

  this.closeRow = function(onCloseParam) {
    var rowToFlash     = this.$rowThatIsOpen;
    var result;
    var cancelCloseRow = false;
    dataGrid.onCloseFunctions.forEach(function(onCloseFunction) {
      result = onCloseFunction(onCloseParam);
      if (typeof result !== "undefined" && result === false) {
        cancelCloseRow = true;
        return false;
      }
    });
    if (cancelCloseRow) {
      return false;
    }
    if (!this.disablePushState) {
      var url = window.location.href;
      url     = dgUrlParam.remove(url, this.rowOpenUrlRowIdName);
      if (this.openRowModeUpdateUrl) {
        url = dgUrlParam.remove(url, this.openRowModeUrlParamName);
      }
      this.closeRowClearUrlParams.forEach(function(urlParamToRemove) {
        url = dgUrlParam.remove(url, urlParamToRemove);
      });
      if (this.rowOpenUrlRequireGrid) {
        url = dgUrlParam.remove(url, this.rowOpenUrlGridParamName);
      }
      window.history.pushState({dataGrid: "yes", mode: "search"}, document.title, url);
    }
    var $hideOnOpen;
    if (dataGrid.openHide === "_grid") {
      $hideOnOpen = $wrapper;
    }
    else {
      $hideOnOpen = $(dataGrid.openHide);
    }
    dataGrid.$open.hide();
    dataGrid.rowOpen             = false;
    dataGrid.rowOpenAjaxFinished = false;
    $hideOnOpen.show();
    if (dataGrid.$rememberScrollDiv !== null) {
      dataGrid.$rememberScrollDiv.scrollTop(dataGrid.rememberScroll);
    }
    $(".dg-clear-on-open-close").html("");

    $(".data-grid-row-flash").removeClass("data-grid-row-flash");
    if (rowToFlash !== null && rowToFlash.length) {
      rowToFlash.addClass("data-grid-row-flash");
      dataGrid.recentlyOpenedRow = rowToFlash.attr("data-row-id");
      setTimeout(function() {
        dataGrid.wrapper.find("tbody[data-row-id='" + dataGrid.recentlyOpenedRow + "']").removeClass("data-grid-row-flash");
        dataGrid.recentlyOpenedRow = null;
      }, 7000);
    }
  };

  this.getRowFromId = function(rowId) {
    return this.wrapper.find("tbody[data-row-id='" + rowId + "']");
  };

  this.openRowAfterLoading = function(rowId, mode, blank) {
    if (!dataGrid.currentlyLoading) {
      var $row = this.getRowFromId(rowId);
      if ($row.length) {
        return this.openRow($row, mode, blank);
      }
      else {
        return false;
      }
    }
    this.rowToOpenAfterLoading = [rowId, mode, blank];
    return true;
  }
  this.updateOpenedRow     = function(row_id) {
    dataGrid.rowOpen = true;
    var $row         = dataGrid.rowElem(row_id);
    if ($row.length) {
      dataGrid.$open.data("row", $row);
      dataGrid.$rowThatIsOpen = $row;
    }
  };
  this.getOpenedRowId      = function() {
    var $openRow = dataGrid.$open.data("row")
    if (typeof $openRow === "undefined" || $openRow === "blank" || !$openRow.length) {
      return null;
    }
    return $openRow.dataGrid().value("row_id");
  }

  this.openRow      = function($row, mode, blank, newWindow, openFirstRowMode) {

    newWindow = newWindow || false;
    if (dataGrid.currentlyLoading && !dataGrid.allowOpenWhileLoading) {
      return true;
    }

    mode             = mode || null;
    blank            = blank || false;
    openFirstRowMode = openFirstRowMode || false;

    if (newWindow || (!dataGrid.disablePushState && $row !== null && $($row).length)) {
      var rowId = $($row).dataGrid().value("row_id");

      var url = window.location.href;

      url = dgUrlParam.add(url, this.rowOpenUrlRowIdName, rowId);
      if (!openFirstRowMode) {
        this.openRowClearUrlParams.forEach(function(urlParamToRemove) {
          url = dgUrlParam.remove(url, urlParamToRemove);
        });
      }
      if (this.rowOpenUrlRequireGrid) {
        url = dgUrlParam.add(url, this.rowOpenUrlGridParamName, dataGrid.name);
      }
      if (this.openRowModeUpdateUrl) {
        if (mode !== null && mode.length > 0) {
          url = dgUrlParam.add(url, this.openRowModeUrlParamName, mode);
        }
        else {
          url = dgUrlParam.remove(url, this.openRowModeUrlParamName);
        }

      }
      if (newWindow) {
        window.open(url);
        return;
      }
      window.history.pushState({dataGrid: "yes", mode: "openRow", rowId: rowId, thisDataGridName: dataGrid.name}, document.title, url);
    }
    var $hideOnOpen;
    var $scrollParent = dataGridHelper.getScrollParent(dataGrid.wrapper[0]);
    if (dataGrid.hideGearErrorsOnRowOpen) {
      dataGrid.$open.find("[data-gear-element='error-wrapper']").each(function() {
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      });
    }
    dataGrid.$open.find("[data-grid-open-mode-required='yes']").each(function() {
      dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      var thisMode = JSON.parse($(this).attr("data-grid-mode"));
      if (thisMode === mode || ($.isArray(thisMode) && $.inArray(mode, thisMode) !== -1)) {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
    });
    if ($scrollParent.is("html")) {
      if (parseInt($scrollParent.scrollTop()) === 0) {
        $scrollParent = $("body");
      }
    }
    if (dataGrid.openHide === "_grid") {
      $hideOnOpen = $wrapper;
    }
    else {
      $hideOnOpen = $(dataGrid.openHide);
    }

    dataGrid.rememberScroll     = $scrollParent.scrollTop();
    dataGrid.$rememberScrollDiv = $scrollParent;
    dataGrid.startOpenRow($row, mode, blank);
    if (dataGrid.$open.find("[data-grid-load='yes']").length || dataGrid.onAfterOpenAjaxFunctions.length || dataGrid.ajaxOpenAlways) {
      if (!blank) {
        dataGrid.openRowAjax($row, mode);
      }
    }
    $hideOnOpen.hide();
    dataGrid.$open.css("display", "flex");
    dataGrid.$open.find("input:not([type='hidden']),textarea,select").filter(":not([readonly])").filter(':visible:first').focus();
    dataGrid.rowOpen        = true;
    dataGrid.$rowThatIsOpen = $row;
    if (blank) {
      dataGrid.$open.data("row", "blank");
    }
    else {
      dataGrid.$open.data("row", $row);
    }

    var openOffset = dataGrid.$open.offset();
    if (typeof openOffset !== "undefined") {
      $scrollParent.scrollTop(dataGrid.$open.offset().top - 100);
    }
    var onOpenFunctionParam = {};
    if (!blank) {
      onOpenFunctionParam = dataGridHelper.getRowData(dataGrid.$open.data("row"));
    }
    $(".dg-clear-on-open-close").html("");
    dataGrid.onOpenFunctions.forEach(function(onOpenFunction) {
      onOpenFunction(onOpenFunctionParam, mode);
    });

    dataGrid.$open.find("[data-toggle='collapse']").each(function() {
      var $collapseHandle = $(this);
      var $target         = $($collapseHandle.attr("data-target"));
      if (!$target.length) {
        return true;
      }
      if ($target.hasClass("show")) {
        $collapseHandle.removeClass("collapsed");
      }
      else {
        $collapseHandle.addClass("collapsed");
      }
    });

    if ($.isFunction($.fn.tooltip)) {
      dataGrid.$open.find("[data-toggle='tooltip']").tooltip({boundary: 'window'});
    }

    dataGridHelper.bindGears(dataGrid.$open);

    return dataGrid;
  };
  this.startOpenRow = function($row, mode, blank) {
    blank = blank || false;
    var row;
    var formattedRow;
    var timeStamps;
    if (blank) {
      row          = {};
      formattedRow = {};
      timeStamps   = {};
    }
    else {
      row          = dataGridHelper.getRowData($row);
      formattedRow = dataGridHelper.getRowData($row, true);
      timeStamps   = dataGridHelper.getRowData($row, "timestamp");
    }

    var $loader = dataGrid.$open.find(".data-grid-open-row-ajax-loader:last");
    var $tabs   = dataGrid.$open.find(".data-grid-open-tab");
    if ($tabs.length) {
      $tabs.removeClass("active");
      var $activeTab = $tabs.filter("[data-grid-tab-mode='" + mode + "']");
      if (!$activeTab.length) {
        $activeTab = $tabs.filter("[data-grid-tab-default='yes']");
      }
      if ($activeTab.length) {
        $activeTab.addClass("active");

      }
    }


    dataGrid.$open.find("[data-grid-open-row-set-on-open='yes']").each(function() {
      $(this).val($(this).attr("data-grid-new-value")).change();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-on-open='yes']").each(function() {
      dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
    });
    dataGrid.$open.find("[data-grid-open-row-show-on-open='yes']").each(function() {
      dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
    });
    dataGrid.$open.find("[data-grid-add-class-on-open='yes']").each(function() {
      $(this).addClass($(this).attr("data-grid-class-to-add"));
    });
    dataGrid.$open.find("[data-grid-remove-class-on-open='yes']").each(function() {
      $(this).removeClass($(this).attr("data-grid-class-to-remove"));
    });
    dataGrid.$open.find("[data-grid-open-row-check-on-open='yes']").prop("checked", true);
    dataGrid.$open.find("[data-grid-open-row-uncheck-on-open='yes']").prop("checked", false);


    dataGrid.$open.find("[data-grid-open-row-hide-empty='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-not-empty='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-same='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-equal='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-less-than='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-greater-than='yes'][data-grid-load='yes']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-empty='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-not-empty='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-same='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-equal='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-less-than='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-greater-than='yes'][data-grid-load='either']").each(function() {
      $(this).hide();
    });

    dataGridHelper.openRowHideEmpty(dataGrid, row, formattedRow);
    dataGridHelper.openRowHideNotEmpty(dataGrid, row, formattedRow);
    dataGridHelper.openRowHideIfSame(dataGrid, row, formattedRow);
    dataGridHelper.openRowHideIfEqual(dataGrid, row, formattedRow);
    dataGridHelper.openRowHideIfGreaterOrLessThan(dataGrid, row, formattedRow);
    dataGridHelper.openRowHideEmpty(dataGrid, row, formattedRow, "either");
    dataGridHelper.openRowHideNotEmpty(dataGrid, row, formattedRow, "either");
    dataGridHelper.openRowHideIfSame(dataGrid, row, formattedRow, "either");
    dataGridHelper.openRowHideIfEqual(dataGrid, row, formattedRow, "either");
    dataGridHelper.openRowHideIfGreaterOrLessThan(dataGrid, row, formattedRow, "either");

    dataGrid.$open.find("[data-grid-open-row-detail='yes'][data-grid-load='no']").each(function() {
      var defaultValueUsed = false;
      var rowToPullFrom    = row;
      var newValue;
      var defaultOnlyOnNew = $(this).attr("data-grid-default-new-only");
      var field            = $(this).attr("data-grid-field");
      var isDatetime       = $(this).attr("data-grid-datetime");
      var isDate           = $(this).attr("data-grid-date");
      var isTime           = $(this).attr("data-grid-time");
      var prepend          = $(this).attr("data-grid-value-prepend");
      var append           = $(this).attr("data-grid-value-append");
      isDatetime           = typeof isDatetime === "string" && isDatetime === "yes";
      isDate               = typeof isDate === "string" && isDate === "yes";
      isTime               = typeof isTime === "string" && isTime === "yes";
      defaultOnlyOnNew     = typeof defaultOnlyOnNew === "string" && defaultOnlyOnNew === "yes";

      if ($(this).attr("data-grid-source") === "field") {
        if ($(this).attr("data-grid-formatted") === "yes") {
          rowToPullFrom = formattedRow;
        }

        if (typeof rowToPullFrom[field] === "undefined" || rowToPullFrom[field].length < 1) {
          newValue = "";
        }
        else {
          newValue = rowToPullFrom[field];
        }
      }
      else {
        var data = dataGrid.gatherVars();
        newValue = data[field];
      }

      if ($(this).attr("data-grid-first-upper") === "yes" && typeof newValue !== "undefined" && newValue.length > 0) {
        newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
      }

      var attrName = $(this).attr("data-grid-attr");

      if (!defaultOnlyOnNew || (typeof row["row_id"] === "undefined" || row["row_id"] === null)) {
        if (typeof newValue === "undefined" || newValue.length < 1) {
          newValue         = $(this).attr("data-grid-default-value");
          defaultValueUsed = true;
        }
      }

      if (typeof prepend !== "undefined") {
        newValue = prepend + newValue;
      }
      if (typeof append !== "undefined") {
        newValue = newValue + append;
      }

      if (typeof attrName === "undefined" || attrName.length < 1 || attrName === "html") {
        if ($(this).attr("data-grid-raw") === "no") {
          $(this).text(newValue);
        }
        else {
          $(this).html(newValue);
        }
      }
      else {
        if (attrName === "value") {
          var timestamp;
          var date;
          if (typeof timeStamps[field] !== "undefined") {
            timestamp = parseInt(timeStamps[field]);
          }
          else {
            timestamp = null;
          }
          if (isDatetime && !defaultValueUsed) {
            if (timestamp === null) {
              newValue = "";
            }
            else {
              date     = new Date(timestamp * 1000);
              newValue = date.toISOString().slice(0, 16);
            }

          }
          if (isDate && !defaultValueUsed) {
            if (timestamp === null) {
              newValue = "";
            }
            else {
              date     = new Date(timestamp * 1000);
              newValue = date.toISOString().slice(0, 10);
            }

          }
          if (isTime && !defaultValueUsed) {
            if (timestamp === null) {
              newValue = "";
            }
            else {
              date     = new Date(timestamp * 1000);
              newValue = date.toISOString().slice(11, 16);
            }

          }
          if (typeof $(this).data("DateTimePicker") !== "undefined") {
            $(this).data("DateTimePicker").clear();
          }
          $(this).val(newValue).change().keyup();
        }
        else if (attrName === "summernote") {
          try {
            $(this).summernote("code", newValue);
          }
          catch (e) {
            var that = this;
            setTimeout(function() {
              $(that).summernote("code", newValue);
            }, 1000);
          }
        }
        else if (attrName === "checked") {
          var check = false;
          if (newValue === $(this).attr("data-grid-check-if")) {
            check = true;
          }
          if (typeof $(this).attr("data-grid-checkbox-inverse") !== "undefined" && $(this).attr("data-grid-checkbox-inverse") === "yes") {
            check = !check;
          }
          if (check) {
            $(this).prop("checked", true).change();
          }
          else {
            $(this).prop("checked", false).change();
          }
        }
        else {
          $(this).attr(attrName, newValue);
        }

      }
    });

    dataGrid.$open.find("[data-grid-open-row-detail='yes'][data-grid-load='yes']").each(function() {
      var loader     = $(this).attr("data-grid-open-loader") === "yes";
      var loaderSize = $(this).attr("data-grid-open-loader-size");
      if (loader && !blank) {
        var $replace = $loader.clone().show();

        if (loaderSize > 0) {
          $replace.css({"font-size": loaderSize + "px"});
          $replace.find("*").css({"font-size": loaderSize + "px"});
        }
        $(this).html($replace);
      }
      else {
        $(this).html("");
      }
    });
  };

  this.addHiddenSearch = function(name, value, skipUrl) {
    skipUrl    = typeof skipUrl !== "undefined" ? skipUrl : true;
    var $input = $(".data-grid-input[data-grid-session-id='" + this.id + "'][name='" + name + "']");
    if (!$input.length) {
      $input = $("<input>");
      $input.attr("name", name);
      $input.addClass("data-grid-input");
      $input.attr("data-grid-session-id", this.id);
      $input.attr("type", "hidden");
      $("body").append($input);
      dataGridHelper.bindSearchElements(this, $input);
    }
    if (skipUrl) {
      $input.attr("data-grid-skip-url", "yes");
    }
    $input.val(value);
    $input.change();
  };

  this.clear = function() {
    this.wrapper.find(".data-grid-tbody").remove();
  }

  this.openRowAjax = function($row, mode) {
    var row = dataGridHelper.getRowData($row, false, true);
    if (typeof mode !== 'undefined' && mode !== null) {
      row._mode = mode;
    }
    if (dataGrid.rowsArePrerendered) {
      row._rowsArePrerendered = true;
    }
    row.row_id = $row.attr("data-row-id");
    dataGrid.openAjaxRequests.forEach(function(thisAjaxRequest) {
      thisAjaxRequest.abort();
    });

    if (this.openRowForwardUrlParams.length > 0) {
      var urlParams = new URLSearchParams(window.location.search);

      this.openRowForwardUrlParams.forEach(function(urlParamToForward) {
        var paramFromUrl = urlParams.get(urlParamToForward);
        if (typeof paramFromUrl !== "undefined" && paramFromUrl !== null && paramFromUrl.length > 0) {
          row[urlParamToForward] = paramFromUrl;
        }
      });
    }
    dataGrid.openAjaxRequests = [];
    var ajaxRequest           = $.ajax({
      url     : dataGrid.ajaxOpenUrl,
      type    : "POST",
      data    : row,
      success : function(response) {
        dataGrid.lastAjaxResponse = response;
        dataGrid.$open.find("[data-grid-open-row-detail='yes'][data-grid-load='yes']").each(function() {
          var field = $(this).attr("data-grid-field");
          if (typeof response[field] === "undefined" || response[field] === null || response[field].length < 1) {
            $(this).html("");
            return true;
          }

          var $divToChange = $(this);


          if ($(this).attr("data-grid-in-iframe") === "yes" && response._iframe[field].cancel !== true) {
            if (typeof iFrameDom === "undefined" || typeof iFrameDom.replace !== "function") {
              console.log("iFrameDom function not found. Install 'skeets.iframe-dom' through npm.");
            }
            else {
              iFrameDom.replace($(this), response[field], response._iframe[field]);
            }
            return true;
          }

          var newValue = response[field];
          if (typeof newValue === "undefined" || newValue.length < 1) {
            newValue = $(this).attr("data-grid-default-value");
          }

          if ($(this).attr("data-grid-raw") === "no") {
            $divToChange.text(newValue);

          }
          else {
            $divToChange.html(newValue);
          }


        });

        dataGridHelper.openRowHideEmpty(dataGrid, response, null, true);
        dataGridHelper.openRowHideNotEmpty(dataGrid, response, null, true);
        dataGridHelper.openRowHideIfSame(dataGrid, response, null, true);
        dataGridHelper.openRowHideIfEqual(dataGrid, response, null, true);
        dataGridHelper.openRowHideIfGreaterOrLessThan(dataGrid, response, null, true);
        dataGridHelper.openRowHideEmpty(dataGrid, response, null, "either");
        dataGridHelper.openRowHideNotEmpty(dataGrid, response, null, "either");
        dataGridHelper.openRowHideIfSame(dataGrid, response, null, "either");
        dataGridHelper.openRowHideIfEqual(dataGrid, response, null, "either");
        dataGridHelper.openRowHideIfGreaterOrLessThan(dataGrid, response, null, "either");

        var onOpenFunctionParam = dataGridHelper.getRowData(dataGrid.$open.data("row"));
        dataGrid.onAfterOpenAjaxFunctions.forEach(function(onAfterOpenAjaxFunction) {
          onAfterOpenAjaxFunction(response, onOpenFunctionParam);
        });
        dataGridHelper.bindGears(dataGrid.$open);
        dataGrid.rowOpenAjaxFinished = true;
      }, error: function(response) {
        if (response.statusText !== "abort") {
          console.log("Ajax error, response:");
          console.log(response);
        }
        if (typeof response.responseJSON !==
            "undefined" &&
            typeof response.responseJSON.message !==
            "undefined" &&
            response.responseJSON.message.length >
            0 &&
            typeof toast ===
            "function") {
          toast({message: response.responseJSON.message, type: "danger"});
        }
        else {
          if (typeof response.responseText !==
              "undefined" &&
              response.responseText.length >
              0 &&
              typeof toast ===
              "function") {
            toast({message: response.responseText, type: "danger"});
          }
        }
        dataGrid.rowOpenAjaxFinished = true;
      }
    });
    dataGrid.openAjaxRequests.push(ajaxRequest);

  };

  this.initBindings($dataGridElem);
  dataGridHelper.removeDuplicate(this.id);
  allDataGrids.push(this);
};


var dataGridHelper = {
  boundPopState  : false,
  $resizingColumn: null,
  allowSorting   : true,
  // safe to call anytime...
  initAll: function() {
    dataGridHelper.auto($("body"));
  },

  auto: function($elem) {
    $elem.find(".data-grid-wrapper").each(function() {
      // Is this datagrid already set up?
      var alreadyExists = $(this).dataGrid();
      if (alreadyExists !== false && alreadyExists !== null) {
        // Yes, skip it...
        return; // equivilant to "continue" in jquery each
      }
      var thisDataGrid = new DataGrid($(this));
      thisDataGrid.gatherVars();

      if (thisDataGrid.openFirstRow) {
        thisDataGrid.openRow(thisDataGrid.getRowNumElem(1), thisDataGrid.openRowMode, false, false, true);
      }
    });
    $elem.find("[data-grid-button-sort='yes']")
         .off("click", dataGridHelper.sortButtonWrapperClick)
         .click(dataGridHelper.sortButtonWrapperClick)
         .find("a")
         .off("click", dataGridHelper.sortButtonWrapperClick)
         .click(dataGridHelper.sortButtonClick);

    $elem.find("[data-grid-toggle-children='yes']")
         .off("click", dataGridHelper.toggleAllChildRowsTrigger)
         .click(dataGridHelper.toggleAllChildRowsTrigger);

    $elem.find("[data-grid-button-search='yes']").off("click", dataGridHelper.searchButtonClick).click(dataGridHelper.searchButtonClick);

    $elem.find(".data-grid-checkbox-row").prop("disabled", false);
    $elem.find(".data-grid-check-all").prop("disabled", false);


    $elem.find(".data-grid-corner-loader").hide();
    $elem.find(".data-grid-corner-refresh").show();
    $elem.find(".data-grid-corner-display-settings").css({visibility: "visible"});
    $elem.find(".data-grid-download-csv").css({visibility: "visible"});
  },

  stickyHeaderResizeFix: function() {
    allDataGrids.forEach(function(thisDataGrid) {
      var stickyHeader = getStickyHeader(thisDataGrid.wrapper);
      stickyHeader.resize();
    });
  },

  openRowHideEmpty              : function(dataGrid, row, formattedRow, ajax) {
    ajax               = ajax || false;
    var selectorAppend = "[data-grid-load='no']";
    if (ajax === true || ajax === "yes") {
      selectorAppend = "[data-grid-load='yes']";
    }
    if (ajax === "either") {
      selectorAppend = "[data-grid-load='either']";
    }

    dataGrid.$open.find("[data-grid-open-row-hide-empty='yes']" + selectorAppend).each(function() {
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldToCheck = $(this).attr("data-grid-field");
      if (typeof rowToPullFrom[fieldToCheck] === "undefined" || rowToPullFrom[fieldToCheck].trim().length < 1) {
        if (ajax === "either") {
          return;
        }
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      }
      else {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
    });
  },
  openRowHideNotEmpty           : function(dataGrid, row, formattedRow, ajax) {
    ajax               = ajax || false;
    var selectorAppend = "[data-grid-load='no']";
    if (ajax === true || ajax === "yes") {
      selectorAppend = "[data-grid-load='yes']";
    }
    if (ajax === "either") {
      selectorAppend = "[data-grid-load='either']";
    }

    dataGrid.$open.find("[data-grid-open-row-hide-not-empty='yes']" + selectorAppend).each(function() {
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldToCheck = $(this).attr("data-grid-field");
      if (typeof rowToPullFrom[fieldToCheck] === "undefined" || rowToPullFrom[fieldToCheck].trim().length < 1) {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
      else {
        if (ajax === "either") {
          return;
        }
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      }
    });

  },
  openRowHideIfSame             : function(dataGrid, row, formattedRow, ajax) {
    ajax               = ajax || false;
    var selectorAppend = "[data-grid-load='no']";
    if (ajax === true || ajax === "yes") {
      selectorAppend = "[data-grid-load='yes']";
    }
    if (ajax === "either") {
      selectorAppend = "[data-grid-load='either']";
    }


    dataGrid.$open.find("[data-grid-open-row-hide-if-same='yes']" + selectorAppend).each(function() {
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldA = $(this).attr("data-grid-field-a");
      var fieldB = $(this).attr("data-grid-field-b");
      if (typeof rowToPullFrom[fieldA] === "undefined" || typeof rowToPullFrom[fieldB] === "undefined") {
        if ((typeof rowToPullFrom[fieldA] === "undefined" || rowToPullFrom[fieldA].length < 1) &&
            (typeof rowToPullFrom[fieldB] === "undefined" || rowToPullFrom[fieldB].length < 1)) {
          if (ajax === "either") {
            return;
          }
          dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
        }
        else {
          dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
        }
      }
      else {
        if (rowToPullFrom[fieldA] === rowToPullFrom[fieldB]) {
          if (ajax === "either") {
            return;
          }
          dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
        }
        else {
          dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
        }
      }
    });

  },
  openRowHideIfEqual            : function(dataGrid, row, formattedRow, ajax) {
    ajax               = ajax || false;
    var selectorAppend = "[data-grid-load='no']";
    if (ajax === true || ajax === "yes") {
      selectorAppend = "[data-grid-load='yes']";
    }
    if (ajax === "either") {
      selectorAppend = "[data-grid-load='either']";
    }


    dataGrid.$open.find("[data-grid-open-row-hide-if-equal='yes']" + selectorAppend).each(function() {
      var showIt        = false;
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldA           = $(this).attr("data-grid-field-a");
      var varToTestAgainst = JSON.parse($(this).attr("data-grid-equal-to-var"));
      if (typeof rowToPullFrom[fieldA] === "undefined" || typeof varToTestAgainst === "undefined") {
        if ((typeof rowToPullFrom[fieldA] === "undefined" || rowToPullFrom[fieldA].length < 1) &&
            (typeof varToTestAgainst === "undefined" || varToTestAgainst.length < 1)) {
          showIt = false;
        }
        else {
          if (!Array.isArray(varToTestAgainst)) {
            showIt = true;
          }
          else {
            showIt = true;
            varToTestAgainst.forEach(function(singleVarToTestAgainst) {
              if (singleVarToTestAgainst.length < 1) {
                showIt = false;
              }
            });
          }
        }
      }
      else {
        if (!Array.isArray(varToTestAgainst)) {
          if ($(this).attr("data-grid-hide-if-contain") === "yes") {
            showIt = rowToPullFrom[fieldA].indexOf(varToTestAgainst) === -1;
          }
          else {
            showIt = rowToPullFrom[fieldA] !== varToTestAgainst;
          }

        }
        else {
          showIt = true;
          varToTestAgainst.forEach(function(singleVarToTestAgainst) {
            if ($(this).attr("data-grid-hide-if-contain") === "yes") {
              if (rowToPullFrom[fieldA].indexOf(varToTestAgainst) !== -1) {
                showIt = false;
              }
            }
            else {
              if (rowToPullFrom[fieldA] === singleVarToTestAgainst) {
                showIt = false;
              }
            }
          });
        }
      }

      if ($(this).attr("data-grid-open-row-negate-if-equal") === "yes") {
        showIt = !showIt;
      }

      if (showIt) {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
      else {
        if (ajax === "either") {
          return;
        }
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      }
    });

  },
  openRowHideIfGreaterOrLessThan: function(dataGrid, row, formattedRow, ajax) {
    ajax               = ajax || false;
    var selectorAppend = "[data-grid-load='no']";
    if (ajax === true || ajax === "yes") {
      selectorAppend = "[data-grid-load='yes']";
    }
    if (ajax === "either") {
      selectorAppend = "[data-grid-load='either']";
    }


    dataGrid.$open.find("[data-grid-open-row-hide-if-less-than='yes']" + selectorAppend).each(function() {
      var showIt        = false;
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldA           = $(this).attr("data-grid-field-a");
      var varToTestAgainst = parseFloat($(this).attr("data-grid-compare-var"));
      if (typeof rowToPullFrom[fieldA] === "undefined" || typeof varToTestAgainst === "undefined") {
        // If either value or comparison value is empty... not really sure what should happen. :P
        showIt = false;
      }
      else {
        var value = parseFloat(rowToPullFrom[fieldA]);
        if ($(this).attr("data-grid-comparison-inclusive") === "yes") {
          // the inclusive looks backwards here, but it's not -- the function is "hide", and this variable is "show",
          // so it ends up looking backwards :P
          showIt = varToTestAgainst < value;
        }
        else {
          showIt = varToTestAgainst <= value;
        }
      }

      if ($(this).attr("data-grid-open-row-negate-if-equal") === "yes") {
        showIt = !showIt;
      }

      if (showIt) {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
      else {
        if (ajax === "either") {
          return;
        }
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      }
    });
    dataGrid.$open.find("[data-grid-open-row-hide-if-greater-than='yes']" + selectorAppend).each(function() {
      var showIt        = false;
      var rowToPullFrom = row;
      if (ajax && $(this).attr("data-grid-formatted") === "yes") {
        rowToPullFrom = formattedRow;
      }
      var fieldA           = $(this).attr("data-grid-field-a");
      var varToTestAgainst = parseFloat($(this).attr("data-grid-compare-var"));
      if (typeof rowToPullFrom[fieldA] === "undefined" || typeof varToTestAgainst === "undefined") {
        // If either value or comparison value is empty... not really sure what should happen. :P
        showIt = false;
      }
      else {
        var value = parseFloat(rowToPullFrom[fieldA]);
        if ($(this).attr("data-grid-comparison-inclusive") === "yes") {
          // the inclusive looks backwards here, but it's not -- the function is "hide", and this variable is "show",
          // so it ends up looking backwards :P
          showIt = varToTestAgainst > value;
        }
        else {
          showIt = varToTestAgainst >= value;
        }
      }

      if ($(this).attr("data-grid-open-row-negate-if-equal") === "yes") {
        showIt = !showIt;
      }

      if (showIt) {
        dataGridHelper.showFunction(this, $(this).attr("data-grid-show-function"));
      }
      else {
        if (ajax === "either") {
          return;
        }
        dataGridHelper.hideFunction(this, $(this).attr("data-grid-show-function"));
      }
    });

  },

  updateTbodyLastTr: function($tbody) {
    if (!$tbody.is("tbody")) {
      $tbody = $tbody.closest("tbody");
    }
    $tbody.find(".data-grid-last-row-in-tbody").removeClass("data-grid-last-row-in-tbody");
    $tbody.find("tr:visible:last").addClass("data-grid-last-row-in-tbody");
  },
  showFunction     : function(element, type) {
    if (typeof type !== "string" || type.length < 1) {
      type = "jquery";
    }
    switch (type) {
      case "bootstrap":
        $(element).addClass("show");
        break;
      case "collapse":
        $(element).collapse("show");
        break;
      case "jquery":
      default:
        $(element).show();
        break;
    }
  },
  hideFunction     : function(element, type) {
    if (typeof type !== "string" || type.length < 1) {
      type = "jquery";
    }
    switch (type) {
      case "bootstrap":
        $(element).removeClass("show");
        break;
      case "collapse":
        $(element).collapse("hide");
        break;
      case "jquery":
      default:
        $(element).hide();
        break;
    }
  },


  saveGridColWidth            : function(field) {
    var dataGrid = dataGridHelper.$resizingColumn.dataGrid();
    if (dataGrid.horizontalScrollVisible()) {
      // don't save if there's a horizontal scroll bar...
      return;
    }
    var cookieName = dataGrid.name + "_" + "col_width_" + field;
    var $cell      = dataGrid.wrapper.find("th[data-field='" + field + "']");
    if (!$cell.length) {
      $cell = dataGrid.wrapper.find("td[data-match-width='" + field + "']");
      if (!$cell.length) {
        $cell = dataGrid.wrapper.find("td[data-grid-field='" + field + "']");
      }
    }
    var cookieValue = $cell.outerWidth();
    Cookies.set(cookieName, cookieValue, {expires: 1});
  },
  dataGridColumnMouseMove     : function(e) {
    if (!dataGridHelper.$resizingColumn.length) {
      dataGridHelper.dataGridColumnMouseUp();
      return true;
    }
    var dataGrid     = dataGridHelper.$resizingColumn.dataGrid();
    var widthInverse = dataGridHelper.$resizingColumn.attr("data-width-inverse");
    var inverseMode  = false;

    var $elementToResize = dataGridHelper.$resizingColumn;
    if (typeof getStickyHeader === 'function') {
      var stickyHeader = getStickyHeader(dataGrid.wrapper);
    }
    if (stickyHeader !== false) {
      if (stickyHeader.floating) {
        $elementToResize = stickyHeader.getHeaderCellPlaceholder(dataGridHelper.$resizingColumn);
      }
    }


    if (typeof widthInverse !== "undefined" && widthInverse.length > 0) {
      inverseMode = true;
    }

    var newWidth = e.pageX - dataGridHelper.$resizingColumn.offset().left - 6 + (parseInt(dataGridHelper.$resizingColumn.css("padding-left")));
    var field    = dataGridHelper.$resizingColumn.attr("data-field");
    var $column  = dataGrid.wrapper.find("td[data-match-width='" + field + "']");
    if (!$column.length) {
      $column = dataGrid.wrapper.find("td[data-grid-field='" + field + "']");
    }

    if (newWidth < 50) {
      newWidth = 50;
    }
    var oldWidth = $elementToResize.outerWidth();
    if (!inverseMode) {
      $elementToResize.css({width: newWidth, "max-width": newWidth, "min-width": newWidth});
      $column.css({width: newWidth, "max-width": newWidth, "min-width": newWidth});
    }
    else {
      var changeWidthBy = oldWidth - newWidth;
      var minWidth      = $elementToResize.css("min-width");
      if (newWidth < parseInt(minWidth)) {
        return true;
      }
      var $targetColumn = dataGrid.wrapper.find("td[data-match-width='" + widthInverse + "']");
      var $targetHeader = dataGrid.wrapper.find("th[data-field='" + widthInverse + "']");
      var targetNewWidth;
      if (!$targetColumn.length) {
        $targetColumn = dataGrid.wrapper.find("td[data-grid-field='" + widthInverse + "']");
      }
      if ($targetHeader.length) {
        targetNewWidth = $targetHeader.outerWidth() + changeWidthBy;
      }
      else {
        targetNewWidth = $targetColumn.outerWidth() + changeWidthBy;
      }
      if (targetNewWidth < 50) {
        targetNewWidth = 50;
      }
      $targetHeader.css({width: targetNewWidth, "max-width": targetNewWidth, "min-width": targetNewWidth});
      $targetColumn.css({width: targetNewWidth, "max-width": targetNewWidth, "min-width": targetNewWidth});
    }

    if (typeof getStickyHeader === 'function') {
      if (stickyHeader !== false) {
        if (stickyHeader.floating) {
          stickyHeader.resize();
        }
      }
    }

  },
  dataGridColumnFinalizeWidths: function() {
    // What does this function do? Just makes the CSS for width related properties match reality.
    if (!dataGridHelper.$resizingColumn.length) {
      return true;
    }
    var dataGrid     = dataGridHelper.$resizingColumn.dataGrid();
    var widthInverse = dataGridHelper.$resizingColumn.attr("data-width-inverse");
    var inverseMode  = false;

    var $elementToResize = dataGridHelper.$resizingColumn;
    if (typeof getStickyHeader === 'function') {
      var stickyHeader = getStickyHeader(dataGrid.wrapper);
    }
    if (stickyHeader !== false) {
      if (stickyHeader.floating) {
        $elementToResize = stickyHeader.getHeaderCellPlaceholder(dataGridHelper.$resizingColumn);
      }
    }
    if (typeof widthInverse !== "undefined" && widthInverse.length > 0) {
      inverseMode = true;
    }

    var field   = dataGridHelper.$resizingColumn.attr("data-field");
    var $column = dataGrid.wrapper.find("td[data-match-width='" + field + "']");
    if (!$column.length) {
      $column = dataGrid.wrapper.find("td[data-grid-field='" + field + "']");
    }

    if (!inverseMode) {
      var elementToResizeWidth = $elementToResize.outerWidth();
      var columnWidth          = $column.outerWidth();
      $elementToResize.css({width: elementToResizeWidth, "max-width": elementToResizeWidth, "min-width": elementToResizeWidth});
      $column.css({width: columnWidth, "max-width": columnWidth, "min-width": columnWidth});
    }
    else {
      var $targetColumn = dataGrid.wrapper.find("td[data-match-width='" + widthInverse + "']");
      var $targetHeader = dataGrid.wrapper.find("th[data-field='" + widthInverse + "']");

      if (!$targetColumn.length) {
        $targetColumn = dataGrid.wrapper.find("td[data-grid-field='" + widthInverse + "']");
      }

      var targetHeaderWidth = $targetHeader.outerWidth();
      var targetColumnWidth = $targetColumn.outerWidth();
      $targetHeader.css({width: targetHeaderWidth, "max-width": targetHeaderWidth, "min-width": targetHeaderWidth});
      $targetColumn.css({width: targetColumnWidth, "max-width": targetColumnWidth, "min-width": targetColumnWidth});
    }

  },
  dataGridColumnMouseUp       : function() {
    dataGridHelper.dataGridColumnFinalizeWidths();
    var field        = dataGridHelper.$resizingColumn.attr("data-width-inverse");
    var widthInverse = dataGridHelper.$resizingColumn.attr("data-width-inverse");
    if (typeof widthInverse === "undefined" || widthInverse.length < 1) {
      field = dataGridHelper.$resizingColumn.attr("data-field");
    }
    $(document).off("mousemove", dataGridHelper.dataGridColumnMouseMove);
    $(document).off("mouseup", dataGridHelper.dataGridColumnMouseUp);
    dataGridHelper.saveGridColWidth(field);
    // only way to prevent this from triggering the sort on release of mouse button...
    dataGridHelper.allowSorting = false;
    setTimeout(function() {
      dataGridHelper.allowSorting = true;
    }, 100);
    dataGridHelper.$resizingColumn.closest("tr").find("td, th").each(function() {
      $(this).css("cursor", $(this).data("original-cursor"));
    });
  },
  dataGridColumnMouseDown     : function() {
    dataGridHelper.$resizingColumn.closest("tr").find("td, th").each(function() {
      $(this).data("original-cursor", $(this).css("cursor"));
      $(this).css("cursor", "col-resize");
    });


  },
  getScrollParent             : function(element) {
    var style               = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";

    if (style.position === "fixed") {
      return $("html");
    }
    for (var parent = element; (parent = parent.parentElement);) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === "static") {
        continue;
      }
      if (/(auto|scroll)/.test(style.overflow + style.overflowY) && parent.scrollHeight > parent.clientHeight) {
        return $(parent);
      }
    }

    return $("html");
  },
  checkDataGrids              : function() {
    var index = allDataGrids.length - 1;
    while (index >= 0) {
      if (!document.body.contains(allDataGrids[index].wrapper[0])) {
        allDataGrids[index].removeFloatLoader();
        allDataGrids.splice(index, 1);
      }

      index -= 1;
    }

  },
  getRowData                  : function($row, formatted, ajaxOpen) {
    if (typeof formatted === "undefined" || (formatted !== true && formatted !== "timestamp")) {
      formatted = false;
    }
    if (typeof ajaxOpen === "undefined" || ajaxOpen !== true) {
      ajaxOpen = false;
    }
    var selector = ".data-grid-data-single";
    if (ajaxOpen) {
      selector = ".data-grid-ajax-open-param";
    }
    var output = {};
    if ($row === "blank") {
      return output;
    }
    $row.find(selector).each(function() {
      var value = dataGridHelper.getCellValue($(this), formatted);
      if (formatted === "timestamp" && (typeof value === "undefined" || value === "")) {
        return true;
      }

      output[$(this).attr("data-grid-field")] = value;
    });
    if (formatted !== "timestamp") {
      output["row_id"] = $row.attr("data-row-id");
    }
    return output;
  },
  getRowDataValue             : function($row, value) {
    if (value === "row_id") {
      return $row.attr("data-row-id");
    }

    var $cell = $row.find(".data-grid-data-single[data-grid-field=" + value + "]");
    if (!$cell.length) {
      return false;
    }
    return dataGridHelper.getCellValue($cell);
  },
  showChildRow                : function($row) {
    $row.find(".data-grid-row-child,.data-grid-sub-row-toggle").attr("data-visible", "yes").show();
    $row.find(".data-grid-hide-on-child-hide").show();
    $row.find(".data-grid-hide-on-child-show").hide();
    dataGridHelper.updateTbodyLastTr($row);

    $row.find(".data-grid-expand-with-child").each(function() {
      var currentRowSpan = $(this).attr("rowspan");
      $(this).attr("rowspan", currentRowSpan + 1);
    });
    if (typeof stickyHeaderResize === 'function') {
      stickyHeaderResize($row.closest(".data-grid-wrapper"));
    }

    return true;
  },
  hideChildRow                : function($row) {
    $row.find(".data-grid-row-child,.data-grid-sub-row-toggle").attr("data-visible", "no").hide();
    $row.find(".data-grid-hide-on-child-show").show();
    $row.find(".data-grid-hide-on-child-hide").hide();
    dataGridHelper.updateTbodyLastTr($row);
    $row.find(".data-grid-expand-with-child").each(function() {
      var currentRowSpan = $(this).attr("rowspan");
      $(this).attr("rowspan", currentRowSpan - 1);
    });
    if (typeof stickyHeaderResize === 'function') {
      stickyHeaderResize($row.closest(".data-grid-wrapper"));
    }
    return true;
  },
  toggleChildRow              : function($row) {
    if ($row.find(".data-grid-row-child,.data-grid-sub-row-toggle").attr("data-visible") === "yes") {
      this.hideChildRow($row);
    }
    else {
      this.showChildRow($row);
    }
    return true;
  },
  getCellValue                : function($cell, formatted) {
    if (typeof formatted === "undefined" || (formatted !== true && formatted !== "timestamp")) {
      formatted = false;
    }

    // This means the formatted & JS values were the same, and the value is only stored in the JS side.
    if (!$cell.find(".data-grid-cell").length && formatted !== "timestamp") {
      formatted = false;
    }

    if (formatted && formatted !== "timestamp") {
      var $inner = $cell.find(".data-grid-cell-inner");
      if ($inner.length) {
        return $inner.html().trim();
      }
      return $cell.html().trim();
    }
    var value;
    if (formatted === "timestamp") {
      return $cell.attr("data-grid-timestamp");
    }

    value = $cell.attr("data-grid-value");
    if (value.length < 1 && !this.jsDefaultToFormatted) {
      return "";
    }
    if (value.length < 1) {
      value = $cell.text().trim();
    }
    return value;
  },
  removeDuplicate             : function(id) {
    allDataGrids.forEach(function(dataGrid, index, array) {
      if (dataGrid.id === id) {
        array.splice(index, 1);
      }
    });
  },
  getDataGrid                 : function(id) {
    var output = null;
    allDataGrids.forEach(function(dataGrid) {
      if (dataGrid.id === id) {
        output = dataGrid;
        return false;
      }
    });
    return output;
  },
  getDataGridFromName         : function(name) {
    var output = null;
    allDataGrids.forEach(function(dataGrid) {
      if (dataGrid.name === name) {
        output = dataGrid;
        return false;
      }
    });
    return output;
  },
  bindPaginationAjax          : function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-pagination-wrapper").on("click", "a", function(e) {
      var page = $(this).attr("data-grid-page");
      if (typeof page === "undefined" || page.length < 1) {
        return true;
      }
      if (dataGrid.search(page)) {
        e.preventDefault();
      }


    });
  },
  bindPageSizeSelect          : function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-page-size-select").on("change", function(e) {
      var pageSize = $(this).val();
      if (typeof pageSize === "undefined" || pageSize.length < 1) {
        return true;
      }
      if (dataGrid.search()) {
        e.preventDefault();
      }


    });
  },
  bindCheckboxes              : function(dataGrid, $dataGridElem) {
    var $checkAll = $dataGridElem.find(".data-grid-check-all");
    $checkAll.on("change", function(e) {
      var $allCheckboxes = dataGrid.wrapper.find(".data-grid-checkbox-row");
      if ($(this).is(":checked")) {
        $allCheckboxes.prop("checked", true);
      }
      else {
        $allCheckboxes.prop("checked", false);
      }

      $($allCheckboxes[0]).change();


    });
    $dataGridElem.find(".data-grid-checkbox-row").on("change", function(e) {
      var numChecked = dataGrid.wrapper.find(".data-grid-checkbox-row:checked:not(:disabled)").length;
      $(".data-grid-num-checked-disp[data-grid-session-id='" + dataGrid.id + "']").each(function() {
        var template;
        if (numChecked === 1) {
          template = $(this).attr("data-single");
        }
        else {
          if (numChecked === 0) {
            template = $(this).attr("data-zero");
          }
          if (typeof template === "undefined" || template === null || template.length < 1) {
            template = $(this).attr("data-plural");
          }
        }
        if (typeof template === "undefined" || template === null || template.length < 1) {
          template = "{#}";
        }
        $(this).attr("data-single")
        $(this).text(template.replace("{#}", numChecked));
      });
      if (numChecked) {
        $checkAll.prop("checked", true);
        dataGrid.wrapper.find(".data-grid-header-bulk-hide").hide();
        dataGrid.wrapper.find(".data-grid-header-bulk-show").show();
        if (typeof stickyHeaderResize === 'function') {
          stickyHeaderResize(dataGrid.wrapper);
        }
      }
      else {
        $checkAll.prop("checked", false);
        dataGrid.wrapper.find(".data-grid-header-bulk-show").hide();
        dataGrid.wrapper.find(".data-grid-header-bulk-hide").show();
        if (typeof stickyHeaderResize === 'function') {
          stickyHeaderResize(dataGrid.wrapper);
        }
      }
    });
    $dataGridElem.find(".data-grid-checkbox-row").on("click", function(e) {
      if (e.shiftKey) {
        var $thisTbody = $(this).closest("tbody");
        var $lastTbody = dataGrid.$lastCheckedCheckbox.closest("tbody");
        if ($thisTbody.is($lastTbody)) {
          return true;
        }
        var $allTbodies;
        if ($thisTbody.nextAll().filter($lastTbody).length !== 0) {
          $allTbodies = $thisTbody.nextUntil($lastTbody).add($thisTbody).add($lastTbody);
        }
        else {
          $allTbodies = $lastTbody.nextUntil($thisTbody).add($thisTbody).add($lastTbody);
        }
        if ($(this).is(":checked")) {
          $allTbodies.find(".data-grid-checkbox-row").prop("checked", true);
        }
        else {
          $allTbodies.find(".data-grid-checkbox-row").prop("checked", false);
        }


      }
      dataGrid.$lastCheckedCheckbox = $(this);
    });
    $dataGridElem.find(".data-grid-checkbox-row-wrapper").mousedown(function(e) {
      e.preventDefault();
      $(":focus").blur();
      $(this).find(".data-grid-checkbox-row").focus();
    });
    $dataGridElem.find(".data-grid-checkbox-row-wrapper").click(function(e) {
      if ($(e.target).is("input")) {
        return true;
      }
      e.preventDefault();
      var clickEvent = new MouseEvent('click', {
        'view'      : window,
        'bubbles'   : true,
        'cancelable': true,
        'shiftKey'  : e.shiftKey
      });

      // body[0].dispatchEvent(clickEvent);
      $(this).find(".data-grid-checkbox-row")[0].dispatchEvent(clickEvent);
      // delete jQuery.event.special.click.trigger; // this "fixes" a jquery bug... :P
      // $(this).find(".data-grid-checkbox-row").trigger($.Event('click', {shiftKey: e.shiftKey}));


      // var $checkbox = $(this).find("input");
      // $checkbox.prop("checked", !$checkbox.prop("checked"));
      // $checkbox.change();
    });
  },
  addAdditionalBinding        : function(dataGrid, settings) {
    dataGridHelper.bindAdditionalSingle(dataGrid, settings, dataGrid.wrapper);
    dataGrid.additionalBindingFunctions.push(settings);
  },
  bindRefreshElements         : function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-corner-refresh").on("click", function(e) {
      if (dataGrid.search()) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  },
  bindChildRowControlElements : function(dataGrid, $dataGridElem) {
    $dataGridElem.find("[data-grid-toggle-children='yes']")
                 .off("click", dataGridHelper.toggleAllChildRowsTrigger)
                 .click(dataGridHelper.toggleAllChildRowsTrigger);

    $dataGridElem.find(".data-grid-show-child-row").on("click", function(e) {
      dataGridHelper.showChildRow($(this).closest("tbody"));
      e.stopPropagation();
    });
    $dataGridElem.find(".data-grid-hide-child-row").on("click", function(e) {
      dataGridHelper.hideChildRow($(this).closest("tbody"));
      e.stopPropagation();
    });
    $dataGridElem.find(".data-grid-child-row-toggle").on("click", function(e) {
      if ($(e.target).closest(".data-grid-checkbox-row-wrapper").length) {
        return true;
      }
      if ($(e.target).closest(".data-grid-no-child-open").length) {
        return true;
      }
      dataGridHelper.toggleChildRow($(this).closest("tbody"));
      e.stopPropagation();
    });
  },
  bindSortElements            : function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-header-cell").off("click", dataGridHelper.sortButtonWrapperClick).click(dataGridHelper.sortButtonWrapperClick);

    $dataGridElem.find(".data-grid-resize-column").on("mousedown", function(e) {
      dataGridHelper.$resizingColumn = $(this).closest("th");
      dataGridHelper.dataGridColumnMouseDown();
      $(document).on("mousemove", dataGridHelper.dataGridColumnMouseMove);
      $(document).on("mouseup", dataGridHelper.dataGridColumnMouseUp);
    });

    $dataGridElem.find(".data-grid-count-cap-warning-wrapper").on("closed.bs.alert", function() {
      $dataGridElem.find(".data-grid-hide-count-cap-warning-wrapper").show();
    });

    $dataGridElem.find(".data-grid-hide-count-cap-warning").click(function() {
      var cookieNamePiece = "hide_count_cap_warning";
      var cookieName      = dataGrid.name + "_" + cookieNamePiece;
      var cookieValue     = "yes";
      Cookies.set(cookieName, cookieValue, {expires: 36500});
      $(this).closest(".alert").alert("close");
    });


    $dataGridElem.find(".data-grid-sort").on("click", dataGridHelper.sortButtonClick);
  },
  bindLinkElements            : function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-change-input").off("click", dataGridHelper.searchButtonClick).click(dataGridHelper.searchButtonClick);
  },


  bindCookieButtons: function(dataGrid, $dataGridElem) {
    $dataGridElem.find(".data-grid-open-settings-dialog").click(function(e) {
      e.preventDefault();
      dataGrid.openSettingsModal();
    });

    if (dataGrid.settingsModal.data("bound") === "yes") {
      return true;
    }
    dataGrid.settingsModal.data("bound", "yes");
    dataGrid.settingsModal.find(".data-grid-reset-col-width").click(function(e) {
      dataGrid.forgetColumnWidths();
      dataGrid.search();
    });
    dataGrid.settingsModal.find(".data-grid-reset-all-settings").click(function(e) {
      dataGrid.settingsModal.find(".data-grid-reset-col-width").click();
      dataGrid.settingsModal.find(".data-grid-set-cookie-radio").each(function() {
        var cookieNamePiece = $(this).attr("data-grid-cookie");
        Cookies.remove(dataGrid.name + "_" + cookieNamePiece);
      });
      dataGrid.settingsModal.find(".data-grid-set-cookie-checkbox").each(function() {
        var cookieNamePiece = $(this).attr("data-grid-cookie");
        Cookies.remove(dataGrid.name + "_" + cookieNamePiece);
      });
      Cookies.remove(dataGrid.name + "_" + "hide_count_cap_warning");
      dataGrid.settingsModal.find("label.active").removeClass("active");
      dataGrid.settingsModal.find("input[data-grid-cookie='fontsize'][data-grid-cookie-value='" + dataGrid.defaultFontSize + "']")
              .prop("checked", true)
              .closest("label")
              .addClass("active");
      dataGrid.settingsModal.find("input[data-grid-cookie='spacing'][data-grid-cookie-value='" + dataGrid.defaultSpacing + "']")
              .prop("checked", true)
              .closest("label")
              .addClass("active");

      dataGrid.search();
    });
    dataGrid.settingsModal.find(".data-grid-set-cookie-radio").change(function(e) {

      var cookieNamePiece = $(this).attr("data-grid-cookie");
      var cookieName      = dataGrid.name + "_" + cookieNamePiece;
      var cookieValue     = $(this).attr("data-grid-cookie-value");
      Cookies.set(cookieName, cookieValue, {expires: 36500});

      var $table = $(false);

      var dataGrids = getDataGrids(dataGrid.name);

      dataGrids.forEach(function(thisDataGrid) {
        $table = $table.add(thisDataGrid.wrapper.find("table"));
      });

      switch (cookieNamePiece) {
        case "spacing":
          $table.removeClass("data-grid-spacing-narrower");
          $table.removeClass("data-grid-spacing-narrow");
          $table.removeClass("data-grid-spacing-normal");
          $table.removeClass("data-grid-spacing-wide");
          $table.removeClass("data-grid-spacing-wider");
          $table.addClass("data-grid-spacing-" + cookieValue);
          if (typeof stickyHeaderResize === 'function') {
            stickyHeaderResize(dataGrid.wrapper);
          }
          break;
        case "fontsize":
          $table.removeClass("data-grid-fontsize-smaller");
          $table.removeClass("data-grid-fontsize-small");
          $table.removeClass("data-grid-fontsize-normal");
          $table.removeClass("data-grid-fontsize-large");
          $table.removeClass("data-grid-fontsize-larger");
          $table.addClass("data-grid-fontsize-" + cookieValue);
          if (typeof stickyHeaderResize === 'function') {
            stickyHeaderResize(dataGrid.wrapper);
          }
          break;
      }

    });
    dataGrid.settingsModal.find(".data-grid-set-cookie-checkbox").change(function(e) {

      // dataGrid.closeSettingsModal();

      var cookieNamePiece = $(this).attr("data-grid-cookie");
      var cookieName      = dataGrid.name + "_" + cookieNamePiece;
      var cookieValue     = $(this).is(":checked");
      Cookies.set(cookieName, cookieValue, {expires: 36500});

      if ($(this).attr("data-grid-refresh") === "yes") {
        dataGrid.search();
      }


    });

  },


  bindSearchElements       : function(dataGrid, $elem) {

    $elem = $elem || $(".data-grid-search[data-grid-session-id='" + dataGrid.id + "']");
    $elem.each(function() {
      if ($(this).hasClass("db-no-auto-search")) {
        return true;
      }
      var boundSearch     = $(this).data("bound_search");
      var searchOperation = $(this).data("search-operation");
      var defaultDate     = new Date();
      if (searchOperation === "less" || searchOperation === "lessEq") {
        defaultDate.setHours(23);
        defaultDate.setMinutes(59);
        defaultDate.setSeconds(59);
      }
      else {
        defaultDate.setHours(0);
        defaultDate.setMinutes(0);
        defaultDate.setSeconds(0);
      }

      if (typeof boundSearch !== "undefined" && boundSearch === true) {
        return true;
      }
      if ($(this).hasClass("dg-datetime-picker")) {
        var format = "YYYY-MM-DD hh:mm a";
        if ($(this).hasClass("dg-datetime-date-no-time")) {
          format = "YYYY-MM-DD";
        }
        var originalVal = $(this).val();
        $(this).datetimepicker({
          icons      : {
            time    : "fa fa-clock",
            date    : "fa fa-calendar",
            up      : "fa fa-arrow-up",
            down    : "fa fa-arrow-down",
            next    : "fa fa-angle-right",
            previous: "fa fa-angle-left"
          },
          keyBinds   : {
            up    : null,
            down  : null,
            right : null,
            left  : null,
            delete: null
          },
          useCurrent : false,
          viewDate   : defaultDate,
          defaultDate: defaultDate,
          format     : format
        }).on("dp.change", function() {
          $(this).keyup();
        });
        $(this).val(originalVal);
      }
      var dataGridSessionId = $(this).attr("data-grid-session-id");
      var inputType;
      if (typeof $(this).attr("type") === "undefined") {
        inputType = "text";
      }
      else {
        inputType = $(this).attr("type");
      }
      if (!$(this).is("input") && !$(this).is("textarea")) {
        inputType = "select";
      }

      switch (inputType) {
        case "text":
        case "search":
          $(this).keyup(function() {
            debounce(function() {
              dataGridHelper.getDataGrid(dataGridSessionId).search();
            }, 1000, null, "dg:" + dataGridSessionId);
          });
          $(this).on("search", function() {
            dataGridHelper.getDataGrid(dataGridSessionId).search();
          });
          break;
        default:
          $(this).change(function() {
            dataGridHelper.getDataGrid(dataGridSessionId).search();
          });
          break;
      }
      $(this).data("bound_search", true);
    });
  },
  bindAddSearchButtons     : function(dataGrid, $elem) {

    $elem = $elem || $(".data-grid-add-search-button[data-grid-session-id='" + dataGrid.id + "']");
    $elem.each(function() {
      var boundClick = $(this).data("bound_click");
      if (typeof boundClick !== "undefined" && boundClick === true) {
        return true;
      }
      var dataGridSessionId = $(this).attr("data-grid-session-id");
      $(this).click(function() {
        dataGridHelper.getDataGrid(dataGridSessionId).addSearch($($(this).attr("data-grid-append-target")), $(this).attr("data-grid-field"), $(this)
            .attr("data-grid-operation"), $(this).attr("data-grid-duplicates-allowed") === "yes", null,
          $(this).attr("data-grid-replace-instead") === "yes",
          $(this).attr("data-grid-do-not-mark-added") === "yes");

      });
      $(this).data("bound_click", true);
    });
  },
  bindSearchForm           : function(dataGrid, $elem) {

    $elem = $elem || $(".data-grid-search-form[data-grid-session-id='" + dataGrid.id + "']");
    $elem.each(function() {
      var boundSearch = $(this).data("bound_search");
      if (typeof boundSearch !== "undefined" && boundSearch === true) {
        return true;
      }

      var dataGridSessionId = $(this).attr("data-grid-session-id");
      $(this).submit(function(e) {
        if (dataGridHelper.getDataGrid(dataGridSessionId).search()) {
          e.preventDefault();
        }

      });
      $(this).on("reset", function(e) {
        if (dataGridHelper.getDataGrid(dataGridSessionId).reset().search()) {
          e.preventDefault();
        }

      });
      $(this).data("bound_search", true);
    });
  },
  bindSettingsButton       : function(dataGrid, $elem) {
    $elem = $elem || dataGrid.wrapper.find(".data-grid-corner-display-settings");
    dataGrid.wrapper.find(".data-grid-download-csv").click(function() {
      var thisDataGrid = dataGridHelper.getDataGrid(dataGrid.id);
      window.location  = thisDataGrid.wrapper.attr("data-csv-url");
    });
    $elem.each(function() {
      var boundSettingsButton = $(this).data("bound_settings_button");
      if (typeof boundSettingsButton !== "undefined" && boundSettingsButton === true) {
        return true;
      }

      $(this).click(function() {
        dataGrid.openSettingsModal();
      });

      $(this).data("bound_settings_button", true);
      return true;
    });
  },
  bindPopState             : function() {
    if (this.boundPopState) {
      return true;
    }
    this.boundPopState = true;
    var thisDatagrid   = this;

    $(window).bind('popstate', function(e) {
      var state           = e.originalEvent.state;
      var preventRedirect = false;
      if (state === null) {
        // prevent from redirecting on a URL hash change (this prevents an infinite loop)
        preventRedirect = true;
      }


      allDataGrids.forEach(function(dataGrid) {
        if (dataGrid.rowOpen) {
          preventRedirect = true;

          var url     = window.location.href;
          var origUrl = url;
          url         = dgUrlParam.remove(url, dataGrid.rowOpenUrlRowIdName);
          if (dataGrid.rowOpenUrlRequireGrid) {
            url = dgUrlParam.remove(url, dataGrid.rowOpenUrlGridParamName);
          }
          if (url === origUrl) {
            dataGrid.disablePushState = true;
          }
          dataGrid.closeRow();
          dataGrid.disablePushState = false;
        }
      });

      if (preventRedirect) {
        return true;
      }
      if (typeof state !== "object" || state.dataGrid !== "yes") {
        state = {mode: "search"};
      }
      if (state.mode === "openRow") {
        var thisDataGrid              = dataGridHelper.getDataGridFromName(state.thisDataGridName);
        thisDataGrid.disablePushState = true;
        thisDataGrid.openRow(thisDataGrid.wrapper.find(".data-grid-row[data-row-id='" + state.rowId + "']"));
        thisDataGrid.disablePushState = false;
        return true;
      }
      // noinspection SillyAssignmentJS
      window.location.href = window.location.href;
    });
  },
  bindRows                 : function(dataGrid, $wrapper) {
    var $tbody = $wrapper.find("tbody");
    if (!$tbody.length && $wrapper.is("tbody")) {
      $tbody = $wrapper;
    }
    if (dataGrid.openOnClick) {
      $tbody.click(function(e) {
        if ($(this).hasClass("data-grid-row-open-excluded")) {
          return true;
        }
        var $target = $(e.target);
        if ($target.hasClass("data-grid-no-row-open") || $target.closest(".data-grid-no-row-open").length) {
          return true;
        }
        if (e.ctrlKey) {
          dataGrid.openRow($(this), null, false, true);
        }
        else {
          dataGrid.openRow($(this));
        }


      });
    }
    if (dataGrid.checkOnClick) {
      $tbody.click(function(e) {
        if ($(this).hasClass("data-grid-row-open-excluded")) {
          return true;
        }
        var $target = $(e.target);
        if ($target.hasClass("data-grid-no-row-check") || $target.closest(".data-grid-no-row-check").length) {
          return true;
        }
        $(this).find(".data-grid-checkbox-row").click();
      });
    }

    var $closeButton = dataGrid.$open.find(".data-grid-details-close-button");
    if ($closeButton.data("bound") !== "yes") {
      $closeButton.click(function() {
        dataGrid.closeRow();
      });
      $closeButton.data("bound", "yes");
    }

    // if (dataGrid.$open.data("bound-escape") !== "yes") {
    // $("body").keyup(function(e) {
    //   if (e.key === "Escape") {
    //     if (Cookies.get(dataGrid.name + "_escapeToClose") !== false || Cookies.get(dataGrid.name + "_escapeToClose") !== "false") {
    //       if (dataGrid.rowOpen) {
    //         dataGrid.closeRow();
    //       }
    //     }
    //   }
    // });
    // dataGrid.$open.data("bound-escape", "yes");
    // }

  },
  bindGears                : function($elem) {
    if (typeof gearJS !== "undefined") {
      gearJS.auto($elem);
    }
  },
  bindAdditional           : function(dataGrid, $wrapper) {
    dataGrid.additionalBindingFunctions.forEach(function(additionalBindingFunction) {
      dataGridHelper.bindAdditionalSingle(dataGrid, additionalBindingFunction, $wrapper);
    });
  },
  bindAdditionalSingle     : function(dataGrid, settings, $wrapper) {
    $wrapper.find(settings.element).on(settings.trigger, function() {
      settings.callback(dataGrid);
    });
  },
  getDataGridFromElement   : function($elem) {
    if (!($elem instanceof jQuery)) {
      $elem = $($elem);
    }
    var dataGridSessionId;
    if ($elem.attr("data-grid-session-id")) {
      dataGridSessionId = $elem.attr("data-grid-session-id");
    }
    else {
      var $wrapper = $elem.closest(".data-grid-wrapper");
      if (!$wrapper.length) {
        $wrapper = $elem.closest(".data-grid-open");
      }
      if (!$wrapper.length) {
        $wrapper = $elem.closest(".data-grid-search-form")
      }
      if (!$wrapper.length) {
        return false;
      }

      dataGridSessionId = $wrapper.attr("data-grid-session-id");
    }
    var output = false;
    allDataGrids.forEach(function(dataGrid) {
      if (dataGrid.id === dataGridSessionId) {
        output = dataGrid;
        return false;
      }
    });
    return output;
  },
  getRowFromElement        : function($elem) {
    if (!($elem instanceof jQuery)) {
      $elem = $($elem);
    }
    var $open = $elem.closest(".data-grid-open")
    if ($open.length) {
      return $open.data("row");
    }
    return $elem.closest(".data-grid-tbody");
  },
  searchButtonClick        : function(e) {
    if (e.ctrlKey) {
      return true;
    }

    var field             = $(this).attr("data-grid-field");
    var searchValue       = $(this).attr("data-grid-search-value");
    var dataGridSessionId = $(this).attr("data-grid-session-id");
    var thisDataGrid      = $(".data-grid-wrapper[data-grid-session-id='" + dataGridSessionId + "']").dataGrid();

    if ($(this).hasClass("active")) {
      thisDataGrid.resetInput($(".data-grid-input[name=" + field + "][data-grid-session-id='" + thisDataGrid.id + "']"));
      return true;
    }

    var checkIt = true;
    if (searchValue === "uncheck") {
      checkIt = false;
    }

    $(".data-grid-input:not([type=submit],[type=radio],[type=checkbox])[name=" + field + "][data-grid-session-id='" + thisDataGrid.id + "']")
      .val(searchValue).change().keyup();
    $(".data-grid-input:not([type=submit],[type=input],[type=select])[name=" + field + "][data-grid-session-id='" + thisDataGrid.id + "']" +
      "[value=" + JSON.stringify(searchValue) + "]")
      .prop("checked", checkIt);
    if (searchValue.length < 1) {
      $(".data-grid-input:not([type=submit],[type=input],[type=select])[name=" + field + "][data-grid-session-id='" + thisDataGrid.id + "']" +
        "[data-grid-radio-blank='yes']")
        .prop("checked", true);
    }
    if (typeof debounce !== "undefined" && typeof debounce["dg:" + dataGridSessionId] !== "undefined") {
      clearTimeout(debounce["dg:" + dataGridSessionId]);
    }
    if (thisDataGrid.search()) {
      e.preventDefault();
    }

  },
  sortButtonClick          : function(e) {
    if (!dataGridHelper.allowSorting) {
      return true;
    }
    if ($(e.target).closest(".data-grid-top-corner").length) {
      return true;
    }
    if ($(e.target).closest(".data-grid-resize-column").length) {
      return true;
    }
    var $input         = $(this).find(".data-grid-input-sort");
    var $allSameSorts  = $(".data-grid-input-sort" +
                           "[data-grid-session-id='" + $input.attr("data-grid-session-id") + "']" +
                           "[name='" + $input.attr("name") + "']");
    var $thisSortOrder = $(this).find(".data-grid-input-sort-order");
    var thisDataGrid   = $(".data-grid-wrapper[data-grid-session-id='" + $input.attr("data-grid-session-id") + "']").dataGrid();
    var $sortOrders    = $(".data-grid-input-sort-order[data-grid-session-id='" + thisDataGrid.id + "']");
    var $sorts         = $(".data-grid-input-sort[data-grid-session-id='" + thisDataGrid.id + "']");

    $thisSortOrder.val(null).change();
    if (e.shiftKey) {
      var largestCount = 0;
      $sorts.each(function() {
        if ($(this).val().length < 1) {
          return true;
        }
        var $thisSortOrder = $(this).closest("a").find(".data-grid-input-sort-order");
        if ($thisSortOrder.val().length < 1) {
          $thisSortOrder.val(0).change();
        }
        if (parseInt($thisSortOrder.val()) > largestCount) {
          largestCount = parseInt($thisSortOrder.val());
        }
      });
      $thisSortOrder.val(largestCount + 1).change();
    }
    else {
      $sortOrders.val(null).change();
      $sorts.val(null).change();
    }

    $allSameSorts.val($input.attr("data-next-value")).change();

    if (thisDataGrid.search()) {
      e.preventDefault();
    }

  },
  toggleAllChildRowsTrigger: function(e) {
    // if it's in a row in the table, toggle that row instead of all rows.
    var $row = $(e.target).closest(".data-grid-tbody");
    if ($row.length) {
      dataGridHelper.toggleChildRow($row);
    }
    else {
      var thisDataGrid = $(".data-grid-wrapper[data-grid-session-id='" + $(this).attr("data-grid-session-id") + "']").dataGrid();
      thisDataGrid.toggleAllChildRows();
    }
  },

  sortButtonWrapperClick: function(e) {
    if (!dataGridHelper.allowSorting) {
      return true;
    }
    if ($(e.target).is("a")) {
      return true;
    }
    if ($(e.target).closest(".data-grid-top-corner").length) {
      return true;
    }
    if ($(e.target).closest(".data-grid-resize-column").length) {
      return true;
    }
    if (e.shiftKey) {
      var shiftClick      = jQuery.Event("click");
      shiftClick.shiftKey = true;
      $(this).find("a").trigger(shiftClick);
    }
    else {
      $(this).find("a").click();
    }
  },
};

dataGridHelper.auto($("body"));

dataGridHelper.bindPopState();


function getDataGrid(name, searchType)
{
  searchType = searchType || "name";
  var output = false;
  allDataGrids.forEach(function(dataGrid) {
    if (searchType === "id" && parseInt(dataGrid[searchType]) === parseInt(name)) {
      output = dataGrid;
      return false;
    }
    if (dataGrid[searchType] === name) {
      output = dataGrid;
      return false;
    }
  });
  return output;
}

function getDataGrids(name)
{
  var output = [];
  allDataGrids.forEach(function(dataGrid) {
    if (dataGrid.name === name) {
      output.push(dataGrid);
    }
  });
  return output;
}

dataGridHelper.bindPopState();

$.fn.dataGridOpen = function(mode) {
  return this.dataGrid("open", mode);
};

$.fn.dataGridRow   = function() {
  return this.dataGrid("row");
};
$.fn.dataGridValue = function(value) {
  return this.dataGrid("value", value);
};

allDataGrids.forEach(function(thisDataGrid) {
  if (thisDataGrid.horizontalScrollVisible()) {
    thisDataGrid.forgetColumnWidths();
  }
});

function dg_escapeStr(str)
{
  if (str) {
    return str.replace(/([ #;?%&,.+*~\':"!^$[\]()=>|\/\\@])/g, '\\$1');
  }

  return str;
}


