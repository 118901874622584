function copyToClipboard(textToCopy, context, allowLineBreaks)
{
  allowLineBreaks = allowLineBreaks || false;

  // Hmmm, can't really think why we wouldn't want linebreaks! Turning this to always on for now as it's breaking things...
  allowLineBreaks = true;
  // "context" is the parent element to place the temporary input in for copying. Required for copying while a
  // dialog box is open, etc.
  var input;
  if (allowLineBreaks) {
    input       = document.createElement('textarea');
    input.value = textToCopy;

    textToCopy = textToCopy.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                           .replace(/"/g, "&quot;").replace(/'/g, "&#039;").replace(/\n/g, "<br>").replace(/ /g, "&nbsp;");
  }
  else {
    input = document.createElement('input');
    input.setAttribute('value', textToCopy);
  }

  var parentElement;
  if (typeof context === "undefined" || context === null) {
    parentElement = document.body;
    parentElement.appendChild(input);
  }
  else {
    parentElement = context;
    parentElement.appendChild(input);
  }

  input.select();
  input.focus();
  var result = document.execCommand('copy');

  parentElement.removeChild(input);

  if (result) {
    if (allowLineBreaks) {
      toast("Copied to clipboard: <br>" + textToCopy.replaceAll("\n", "<br>"));
    }
    else {
      toast("Copied to clipboard: " + textToCopy);
    }
  }
  else {
    toast("Failed to copy text");
  }
  return result;
}

$("body").on("click", ".copy-to-clipboard", function(e) {
  var text        = $(this).attr("data-text");
  var allowBreaks = $(this).attr("data-allow-breaks") === "yes";
  if (typeof text === "string" && text.length > 0) {
    copyToClipboard(text, null, allowBreaks);
  }
  else {
    toast("Nothing to copy!");
  }
  e.stopPropagation();
  e.preventDefault();
});

