$.fn.hasClassStartingWith = function(needle) {
  var found_match = false;
  $(this).each(
    function() {
      var class_list = this.className.split(/\s+/);
      $.each(class_list, function() {
        if (this.indexOf(needle) == 0) {
          found_match = true;
        }
      });
    }
  );
  return found_match;
};
