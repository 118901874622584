$("body").on("submit", ".markdown-refresh-query-form", function(e) {
  e.preventDefault();
  var postData = serializedArrayToObject($(this).serializeArray());
  var that     = this;
  $.ajax({
    url    : $(this).attr("action"),
    type   : "POST",
    data   : postData,
    success: function(response) {
      $(that).closest(".card").replaceWith(response);

    }
    , error: function(response) {
      toast("Query refresh error");
    }
  });
});


function serializedArrayToObject(array)
{
  var output = {};
  array.forEach(function(element) {
    if (typeof output[element.name] === "undefined") {
      output[element.name] = element.value;
    }
    else {
      if (typeof output[element.name] === "object") {
        output[element.name].push(element.value);
      }
      else {
        output[element.name] = [output[element.name], element.value];
      }

    }

  });

  return output;
}

