var gearJS = {
  activeAjax           : {},
  confirmDialogAppended: false,
  allGears             : [],
  promptNeeded         : [],

  copyToClipboard: function(textToCopy, context) {
    // "context" is the parent element to place the temporary input in for copying. Required for copying while a
    // dialog box is open, etc.
    var input = document.createElement('input');
    input.setAttribute('value', textToCopy);
    var parentElement;
    if (typeof context === "undefined") {
      parentElement = document.body;
      document.body.appendChild(input);
    }
    else {
      parentElement = context;
      context.appendChild(input);
    }

    input.select();
    input.focus();
    var result = document.execCommand('copy');

    parentElement.removeChild(input);

    if (result) {
      toast("Copied to clipboard: " + textToCopy);
    }
    else {
      toast("Failed to copy text: " + textToCopy);
    }
    return result;
  },

  handleSuccess: function($ele, response, loadingToast) {
    // retrieve these first, since the element may not exist later...
    var defaultUpdateToast = $ele.gearJsAttr("data-gear-default-update-toast");
    var alwaysActions      = $ele.gearJsAttr("data-gear-always-actions");
    var toastUpdated       = gearJS.runActions(response, loadingToast, $ele);
    if (!toastUpdated) {
      gearJS.runActions(defaultUpdateToast, loadingToast, $ele);
    }
    gearJS.runActions(alwaysActions, loadingToast, $ele);
  },

  handleError: function($ele, response, loadingToast) {
    // need to make sure we close the stream if there is one!
    gearJS.killStream($ele);

    if (response.readyState === 0 && response.statusText !== "abort") {
      if (loadingToast !== null) {
        loadingToast.set({time: 0});
      }
      toast({"message": "Failed to connect. Check your internet connection and try again.", type: "danger"});
      return true;
    }
    gearJS.hideErrors($ele);
    var errorHandled = false;
    if (response.statusText !== "abort") {
      var error;
      var responseIsObject = true;
      try {
        error = JSON.parse(response.responseText);
      }
      catch (e) {
        responseIsObject = false;
      }
      var toastUpdated = false;
      if (responseIsObject && typeof error.actions !== "undefined") {
        toastUpdated = gearJS.runActions(error.actions, loadingToast, $ele);
        errorHandled = true;
      }
      if (typeof response.status !== "undefined" && parseInt(response.status) === 413) {
        var error413          = $ele.gearJsAttr("data-gear-error-413") || "Request too large";
        var largeRequestToast = {
          message: error413,
          time   : 5000,
          type   : "danger"
        };
        if (loadingToast !== null) {
          loadingToast.set(largeRequestToast);
        }
        else {
          toast(largeRequestToast);
        }
        toastUpdated = true;
        errorHandled = true;
      }


      var errorToastMessage = {};
      var errorToastIcon    = $ele.gearJsAttr("data-gear-error-toast-icon");
      var errorToastType    = $ele.gearJsAttr("data-gear-error-toast-type");
      var errorToastAlways  = $ele.gearJsAttr("data-gear-error-toast-always") === "yes";
      var time              = $ele.gearJsAttr("data-gear-error-toast-time");

      if (responseIsObject && typeof error.message !== "undefined") {
        if (typeof error.errors === "undefined") {
          error.errors = {_gear_server_error: error.message};
        }
        else {
          error.errors._gear_generic = error.message;
        }
      }

      if (responseIsObject && typeof error.errors !== "undefined") {
        if (loadingToast !== null && !toastUpdated) {
          loadingToast.set({time: 0});
        }
        errorToastMessage = error.errors;
        errorHandled      = true;
      }
      else {
        if (!toastUpdated) {
          var updateToastMsg;
          if (typeof response === "object" && typeof response.responseText === "string") {
            updateToastMsg = response.responseText;
          }
          else {
            if (typeof response === "string") {
              updateToastMsg = response;
            }
          }
          if (typeof updateToastMsg === "undefined" ||
              typeof updateToastMsg !== "string" ||
              updateToastMsg.length < 1 ||
              updateToastMsg.indexOf("<html") !== -1) {
            updateToastMsg = $ele.gearJsAttr("data-gear-error-toast");
          }
          var errorToastSettings = {
            message: updateToastMsg,
            icon   : errorToastIcon,
            time   : time,
            type   : errorToastType
          };
          if (loadingToast !== null) {
            loadingToast.set(errorToastSettings);
          }
          else {
            toast(errorToastSettings);
          }
        }
      }


      if (errorToastMessage.length < 1) {
        time = 0;
      }
      for (var fieldName in errorToastMessage) {
        if (errorToastMessage.hasOwnProperty(fieldName)) {
          if ($.isArray(errorToastMessage[fieldName])) {
            errorToastMessage[fieldName].forEach(function(errorSingleMessage) {
              gearJS.errorDisp($ele, errorSingleMessage, fieldName, errorToastAlways, errorToastIcon, time, errorToastType);
            });
          }
          else {
            gearJS.errorDisp($ele, errorToastMessage[fieldName], fieldName, errorToastAlways, errorToastIcon, time, errorToastType);
          }
        }
      }

      gearJS.runActions($ele.gearJsAttr("data-gear-error-actions"), loadingToast, $ele);
      gearJS.runActions($ele.gearJsAttr("data-gear-always-actions"), loadingToast, $ele);

      if (!errorHandled) {
        console.log("Ajax error, response:");
        console.log(response);
      }
    }
    else {
      var cancelToastMessage = $ele.gearJsAttr("data-gear-cancel-toast");
      var cancelToastIcon    = $ele.gearJsAttr("data-gear-cancel-toast-icon");
      if (typeof cancelToastMessage === "undefined" || cancelToastMessage.length < 1) {
        if (loadingToast !== null) {
          loadingToast.close();
        }
        return true;
      }
      var toastSettings = {
        time   : 1000,
        message: cancelToastMessage,
        type   : "warning",
        icon   : cancelToastIcon
      };
      if (loadingToast !== null) {
        loadingToast.set(toastSettings);
      }
      else {
        toast(toastSettings);
      }
      gearJS.runActions($ele.gearJsAttr("data-gear-always-actions"), loadingToast, $ele);
    }

  },

  prepareConfirmText         : function($ele, text) {
    if (typeof text !== "string") {
      return text;
    }
    var confirmSources = $ele.gearJsAttr("data-gear-confirm-sources");
    var customVars     = gearJS.gatherDataInner($ele, confirmSources);
    var mode           = $ele.gearJsAttr("data-gear-mode");
    if (typeof customVars.mode === "undefined" && mode !== null && mode.length > 0) {
      customVars.mode = mode;
    }
    for (var varName in customVars) {
      if (customVars.hasOwnProperty(varName)) {
        text = gearJS.handleCustomConfirmVariable(text, varName, customVars[varName]);
      }
    }

    if (typeof dataGridHelper !== "undefined") {


      var $dataGridElement        = gearJS.element($ele.gearJsAttr("data-gear-confirm-data-grid-count"), $ele);
      var $confirmCountCheckboxes = $($ele.gearJsAttr("confirm-count-checkbox-selector"));
      var skipIfNoneSelected      = $ele.gearJsAttr("data-gear-confirm-skip-none-selected") === "yes";

      var thisDataGrid = $dataGridElement.dataGrid();

      if (thisDataGrid !== false || $confirmCountCheckboxes.length) {


        var numChecked;
        var numAll;
        var numTotal;
        if (thisDataGrid === false) {
          numAll     = $confirmCountCheckboxes.length;
          numTotal   = numAll;
          numChecked = $confirmCountCheckboxes.filter(":checked").length;
        }
        else {
          numChecked = thisDataGrid.wrapper.find(".data-grid-checkbox-row:checked").length;
          numAll     = thisDataGrid.wrapper.find(".data-grid-tbody").length;
          numTotal   = parseInt(thisDataGrid.wrapper.attr("data-grid-total-records"));
        }


        if (numChecked === 0 && skipIfNoneSelected) {
          return "_gearjs_skip";
        }

        var replacePlural = "$1";

        if (numChecked === 1) {
          replacePlural = "";
        }
        var replaceSingle = "";

        if (numChecked === 1) {
          replaceSingle = "$1";
        }
        var replacePluralAll = "$1";

        if (numAll === 1) {
          replacePluralAll = "";
        }
        var replacePluralTotal = "$1";

        if (numTotal === 1) {
          replacePluralTotal = "";
        }
        text = text.replace(/{#}/g, numChecked)
                   .replace(/{pl=([^}]+)}/g, replacePlural)
                   .replace(/{pl!=([^}]+)}/g, replaceSingle)
                   .replace(/{#all}/g, numAll)
                   .replace(/{pl:all=([^}]+)}/g, replacePluralAll)
                   .replace(/{#total}/g, numTotal)
                   .replace(/{pl:total=([^}]+)}/g, replacePluralTotal);
      }
    }

    text = text.replace(/{empty\(([^)]*)\)([^}]*)?}/g, function(fullMatch, group1, group2) {
      var testVar = $ele.attr(group1);
      if (typeof customVars[group1] !== "undefined") {
        testVar = customVars[group1];
      }
      if (typeof testVar === "undefined" || testVar === null || testVar.toString().trim().length < 1) {
        return group2;
      }
      return "";

    });
    text = text.replace(/{!empty\(([^)]*)\)([^}]*)?}/g, function(fullMatch, group1, group2) {
      var testVar = $ele.attr(group1);
      if (typeof customVars[group1] !== "undefined") {
        testVar = customVars[group1];
      }
      if (typeof testVar !== "undefined" && testVar !== null && testVar.toString().trim().length > 0) {
        return group2;
      }
      return "";
    });

    text = text.replace(/{if\(([^=<>()!]*)([=<>!]{1,3})([^=<>()]*)\)([^}]*)}/g, function(fullMatch, group1, group2, group3, group4) {
      var compareVar = $ele.attr(group1);
      if (typeof customVars[group1] !== "undefined") {
        compareVar = customVars[group1];
      }
      switch (group2) {
        case "==":
        case "===":
        case "=":
        default:
          if (compareVar === group3) {
            return group4;
          }
          break;
        case "!==":
        case "!=":
          if (compareVar !== group3) {
            return group4;
          }
          break;
        case "<":
          if (parseInt(compareVar) < parseInt(group3)) {
            return group4;
          }
          break;

        case "<=":
          if (parseInt(compareVar) <= parseInt(group3)) {
            return group4;
          }
          break;
        case ">":
          if (parseInt(compareVar) > parseInt(group3)) {
            return group4;
          }
          break;
        case ">=":
          if (parseInt(compareVar) >= parseInt(group3)) {
            return group4;
          }
          break;
      }

      return "";
    });

    return text;
  },
  handleCustomConfirmVariable: function(text, customVarName, customVarValue) {
    if (typeof customVarValue === "object" && typeof customVarValue.value !== "undefined") {
      customVarValue = customVarValue.value;
    }
    var regex = new RegExp("{" + customVarName + "}", "g");
    return text.replace(regex, customVarValue);
  },
  confirm                    : function($ele, callback) {
    var title = gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-confirm-title"));
    if (title === "_gearjs_skip") {
      callback();
    }
    else {
      onConfirm({
        callback  : callback,
        noCallback: function() {
          gearJS.runActions($ele.gearJsAttr("data-gear-confirm-cancel-actions"), null, $ele);
        },
        title     : title,
        body      : gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-confirm-body")),
        cancel    : gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-confirm-cancel")),
        yes       : gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-confirm-yes")),
        yesType   : $ele.gearJsAttr("data-gear-confirm-yes-class"),
        timer     : $ele.gearJsAttr("data-gear-confirm-time")
      });
    }

  },
  newlyAddedHtml             : function($element) {
    if (typeof dataGridHelper !== "undefined") {
      dataGridHelper.auto($element);
    }
    gearJS.auto($element);
    if (typeof conditionalJsAuto !== "undefined") {
      conditionalJsAuto($element);
    }
  },
  auto                       : function($elem, includeDelayed) {
    includeDelayed = includeDelayed || false;
    if (typeof $elem !== "undefined") {
      gearJS.init($elem.find("[data-gear-enabled='yes']"));
      if (includeDelayed) {
        gearJS.init($elem.find("[data-gear-enabled='delayed']"));
      }
    }
    else {
      gearJS.init($("[data-gear-enabled='yes']"));
      if (includeDelayed) {
        gearJS.init($("[data-gear-enabled='delayed']"));
      }
    }
    gearJS.allGears.forEach(function(gear) {
      // If any child elements of existing gears have been added, they need to be initialized.
      var trigger = gear.$ele.gearJsAttr("data-gear-trigger");
      if (typeof trigger === "undefined" || trigger.length < 1) {
        trigger = "click";
      }
      if (trigger === "submit" || gear.$ele.is("form")) {
        gearJS.initSubmitButtons(gear.$ele);
      }
    });
  },
  hideErrors                 : function($ele) {
    var gearName = $ele.gearJsAttr("data-gear-name");
    $("[data-gear-error-name='" + gearName + "']").hide();
  },
  initSubmitButtons          : function($form) {
    var $submitButtons = $form.find("input[type=submit], button");

    $submitButtons.each(function() {
      if ($(this).data("gear-submit-button-initialized") === "yes") {
        return;
      }
      $(this).click(function() {
        $submitButtons.attr("data-gear-clicked-submit", "no");
        $(this).attr("data-gear-clicked-submit", "yes");
        $(this).data("gear-submit-button-initialized", "yes");
      });
    });
  },
  triggerFunction            : function($ele) {
    var debounceTime = $ele.gearJsAttr("data-gear-debounce-time");

    debounce(function() {
      gearJS.hideErrors($ele);
      var data = gearJS.gatherData($ele);
      if (gearJS.promptNeeded.length < 1) {
        gearJS.afterGatherData($ele, data);
      }
      else {
        var preparedPrompts = {};
        var options         = {};
        var confirmTime     = parseInt($ele.gearJsAttr("data-gear-confirm-time"));
        gearJS.promptNeeded.forEach(function(thisPrompt) {
          preparedPrompts[thisPrompt.fieldName] =
            {
              label        : gearJS.prepareConfirmText($ele, thisPrompt.promptLabel),
              inputType    : thisPrompt.promptDialog.inputType,
              attr         : thisPrompt.promptDialog.attr,
              class        : thisPrompt.promptDialog.class,
              showWhenField: thisPrompt.promptDialog.showWhenField || null,
              showWhenValue: thisPrompt.promptDialog.showWhenValue || null,
              beforeInput  : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.beforeInput),
              afterInput   : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.afterInput),
              default      : gearJS.prepareConfirmText($ele, thisPrompt.defaultPromptValue),
              placeholder  : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.placeholder),
              labelWidth   : thisPrompt.promptDialog.labelWidth,
              yesType      : thisPrompt.promptDialog.yesType,
            };
          if (thisPrompt.promptLabel === null || thisPrompt.promptLabel.length < 1) {
            preparedPrompts[thisPrompt.fieldName].hideLabel = true;
          }
          if (thisPrompt.promptDialog.inputType === "radio") {
            preparedPrompts[thisPrompt.fieldName].radioOptions = thisPrompt.promptDialog.selectOptions;
          }
          else {
            preparedPrompts[thisPrompt.fieldName].selectOptions = thisPrompt.promptDialog.selectOptions;
          }
          if (thisPrompt.promptDialog.inputType === "checkbox") {
            preparedPrompts[thisPrompt.fieldName].checked =
              (thisPrompt.promptDialog.checkedWhen ===
               thisPrompt.defaultPromptValue ||
               thisPrompt.value ===
               thisPrompt.promptDialog.checkedWhen);
          }

          if (thisPrompt.promptDialog.title !== null ||
              thisPrompt.promptDialog.msg !== null ||
              thisPrompt.promptDialog.yes !== null ||
              thisPrompt.promptDialog.no !== null ||
              thisPrompt.promptDialog.hideOnSubmit !== null) {
            for (var propName in thisPrompt.promptDialog) {
              if (thisPrompt.promptDialog.hasOwnProperty(propName)) {
                if (thisPrompt.promptDialog[propName] !== null && thisPrompt.promptDialog[propName] !== undefined) {
                  var thisValue = thisPrompt.promptDialog[propName];
                  if (["title", "msg", "body", "yes", "no"].indexOf(propName) !== -1) {
                    thisValue = gearJS.prepareConfirmText($ele, thisValue);
                  }
                  options[propName] = thisValue;
                }
              }
            }
          }

        });
        options.noCallback = function() {
          gearJS.runActions($ele.gearJsAttr("data-gear-confirm-cancel-actions"), null, $ele);
        };
        options.callback   = function(userInput) {
          for (var prop in userInput) {
            if (userInput.hasOwnProperty(prop)) {
              data[prop] = userInput[prop];
            }
          }
          gearJS.afterGatherData($ele, data);
        };
        if (typeof confirmTime !== "undefined" && confirmTime !== null && !isNaN(confirmTime) && confirmTime > 0) {
          options.timer = confirmTime;
        }
        promptInput(preparedPrompts, options);
      }
    }, debounceTime, $ele, $ele.gearJsAttr("name"));
  },
  afterGatherData            : function($ele, data) {
    //var loadingToastMessage = $ele.gearJsAttr("data-gear-loading-toast");
    var removalWorkaround = $ele.gearJsAttr("data-gear-removal-workaround") === "yes";
    if (removalWorkaround) {
      $ele.detach();
    }
    var loadingToastMessage = gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-loading-toast"));
    var loadingToastIcon    = $ele.gearJsAttr("data-gear-loading-toast-icon");
    var gearName            = $ele.gearJsAttr("data-gear-name");
    var cancelDuplicate     = $ele.gearJsAttr("data-gear-cancel-duplicate") === "yes";
    var loadingToast        = null;
    if (typeof loadingToastMessage === "undefined") {
      loadingToastMessage = "";
    }
    if (typeof loadingToast !== "undefined" && loadingToastMessage.length > 0) {
      loadingToast = toast({"message": loadingToastMessage, "time": -1, "icon": loadingToastIcon})
    }
    gearJS.runActions($ele.gearJsAttr("data-gear-trigger-actions"), loadingToast, $ele);

    if ($ele.gearJsAttr("data-gear-ajax-enabled") === "no") {
      return true;
    }

    if (typeof gearJS.activeAjax[gearName] === "undefined") {
      gearJS.activeAjax[gearName] = [];
    }


    if (cancelDuplicate) {
      gearJS.activeAjax[gearName].forEach(function(ajaxRequest) {
        ajaxRequest.abort();
      });
    }

    gearJS.activeAjax[gearName] = [];

    var mode = $ele.gearJsAttr("mode");
    if (typeof mode !== "undefined" && mode !== null && mode.length > 0) {
      data._gear_mode = mode;
    }

    var containsFile = gearJS.dataContainsFile(data);

    var form_data;
    // these are the defaults https://api.jquery.com/jquery.ajax/
    var processData = true;
    var contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    var cache       = true;

    if (!containsFile) {
      // If there's no file in the data, no need to use the over-complicated FormData object :P
      form_data = data;
    }
    else {
      processData = false;
      contentType = false;
      cache       = false;
      form_data   = gearJS.convertToFormData(data);
    }

    // noinspection JSUnusedGlobalSymbols


    gearJS.doAjax(processData, contentType, cache, $ele, form_data, loadingToast);
  },
  generateStreamId           : function() {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  },
  killStream                 : function($ele) {
    if (typeof $ele === "undefined" || $ele === null || !$ele.length) {
      return;
    }
    var eventSource = $ele.data("_gear_stream_event_source");
    if (typeof eventSource === "undefined" || eventSource === null) {
      return;
    }
    eventSource.close();
  },
  doAjax                     : function(processData, contentType, cache, $ele, form_data, loadingToast) {
    var removalWorkaround = $ele.gearJsAttr("data-gear-removal-workaround") === "yes";
    var gearName          = $ele.gearJsAttr("data-gear-name");
    if (typeof gearName === "undefined" || gearName === null || gearName.length < 1) {
      console.error("ERROR: gear name not found. Was the element removed during 'startAction'?");
      // Try setting removalWorkaround to true (However, this won't work if you use any relative elements, as it detaches the gear from the
      // DOM.)
      return;
    }

    if ($ele.gearJsAttr("data-gear-stream-results") === "yes") {
      var stream_id = gearJS.generateStreamId();

      gearJS.addToData(form_data, "_gear_stream_id", stream_id);
      gearJS.addToData(form_data, "_gear_user_id", $ele.gearJsAttr("data-gear-user-id"));

      if (typeof (EventSource) !== "undefined") {
        var eventSource = new EventSource($ele.gearJsAttr("data-gear-stream-url") + "/" + stream_id);

        eventSource.onmessage = function(event) {
          if (typeof event.data === "string" && event.data.trim().toLowerCase() === "stop") {
            eventSource.close();
            return;
          }
          var response = JSON.parse(event.data);
          gearJS.runActions(response, loadingToast, $ele);
        };
        $ele.data("_gear_stream_event_source", eventSource);
      }
      else {
        console.error("Your browser does not support server-sent events.");
      }
    }

    var ajaxRequest = $.ajax({
      url        : $ele.gearJsAttr("data-gear-ajax-url"),
      type       : $ele.gearJsAttr("method"),
      data       : form_data,
      dataType   : "json",
      processData: processData,
      contentType: contentType,
      cache      : cache,
      success    : function(response) {
        gearJS.hideErrors($ele);
        if (typeof response === "undefined" || response === null) {
          return false;
        }
        if (typeof response === "string") {
          try {
            response = JSON.parse(response);
          }
          catch (e) {
            return false;
          }
        }

        if (typeof response._gear_dynamicConfirm !== "undefined" &&
            response._gear_dynamicConfirm.length > 0 &&
            response._gear_dynamicConfirm === "yes") {

          if (typeof loadingToast !== "undefined" && loadingToast !== null) {
            loadingToast.set({time: 0});
          }
          // START
          // START
          // START
          // START

          var preparedPrompts = {};
          var options         = {};
          var confirmTime     = parseInt($ele.gearJsAttr("data-gear-confirm-time"));
          response._gear_additional_inputs.forEach(function(thisPrompt) {
            preparedPrompts[thisPrompt.fieldName] =
              {
                label        : gearJS.prepareConfirmText($ele, thisPrompt.promptLabel),
                inputType    : thisPrompt.promptDialog.inputType,
                attr         : thisPrompt.promptDialog.attr,
                class        : thisPrompt.promptDialog.class,
                showWhenField: thisPrompt.promptDialog.showWhenField || null,
                showWhenValue: thisPrompt.promptDialog.showWhenValue || null,
                beforeInput  : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.beforeInput),
                afterInput   : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.afterInput),
                default      : gearJS.prepareConfirmText($ele, thisPrompt.value),
                placeholder  : gearJS.prepareConfirmText($ele, thisPrompt.promptDialog.placeholder),
                labelWidth   : thisPrompt.promptDialog.labelWidth,
                yesType      : thisPrompt.promptDialog.yesType,
              };
            if (thisPrompt.promptLabel === null || thisPrompt.promptLabel.length < 1) {
              preparedPrompts[thisPrompt.fieldName].hideLabel = true;
            }
            if (thisPrompt.promptDialog.inputType === "radio") {
              preparedPrompts[thisPrompt.fieldName].radioOptions = thisPrompt.promptDialog.selectOptions;
            }
            else {
              preparedPrompts[thisPrompt.fieldName].selectOptions = thisPrompt.promptDialog.selectOptions;
            }
            if (thisPrompt.promptDialog.inputType === "checkbox") {
              preparedPrompts[thisPrompt.fieldName].checked =
                (thisPrompt.promptDialog.checkedWhen ===
                 thisPrompt.defaultPromptValue ||
                 thisPrompt.value ===
                 thisPrompt.promptDialog.checkedWhen);
            }

            if (thisPrompt.promptDialog.title !== null ||
                thisPrompt.promptDialog.msg !== null ||
                thisPrompt.promptDialog.yes !== null ||
                thisPrompt.promptDialog.no !== null ||
                thisPrompt.promptDialog.hideOnSubmit !== null) {
              for (var propName in thisPrompt.promptDialog) {
                if (thisPrompt.promptDialog.hasOwnProperty(propName)) {
                  if (thisPrompt.promptDialog[propName] !== null && thisPrompt.promptDialog[propName] !== undefined) {
                    var thisValue = thisPrompt.promptDialog[propName];
                    if (["title", "msg", "body", "yes", "no"].indexOf(propName) !== -1) {
                      thisValue = gearJS.prepareConfirmText($ele, thisValue);
                    }
                    options[propName] = thisValue;
                  }
                }
              }
            }

          });
          options.noCallback = function() {
            gearJS.runActions($ele.gearJsAttr("data-gear-confirm-cancel-actions"), loadingToast, $ele);
          };
          options.callback   = function(userInput) {
            if (!(form_data instanceof FormData)) {
              if (gearJS.dataContainsFile(userInput)) {
                processData = false;
                contentType = false;
                cache       = false;
                form_data   = gearJS.convertToFormData(form_data);
              }
            }

            for (var prop in userInput) {
              if (userInput.hasOwnProperty(prop)) {
                if (form_data instanceof FormData) {
                  gearJS.appendSingleToFormData(form_data, prop, userInput[prop]);
                }
                else {
                  form_data[prop] = userInput[prop];
                }

              }
            }
            var loadingToastMessage = gearJS.prepareConfirmText($ele, $ele.gearJsAttr("data-gear-loading-toast"));
            var loadingToastIcon    = $ele.gearJsAttr("data-gear-loading-toast-icon");
            if (typeof loadingToast !== "undefined" && loadingToast !== null && loadingToastMessage.length > 0) {
              loadingToast = toast({"message": loadingToastMessage, "time": -1, "icon": loadingToastIcon})
            }
            gearJS.doAjax(processData, contentType, cache, $ele, form_data, loadingToast);
          };
          for (var propNameConfirmDialog in response._gear_confirmDialog) {
            if (response._gear_confirmDialog.hasOwnProperty(propNameConfirmDialog)) {
              if (response._gear_confirmDialog[propNameConfirmDialog] !==
                  null &&
                  response._gear_confirmDialog[propNameConfirmDialog] !==
                  undefined) {
                var thisValue = response._gear_confirmDialog[propNameConfirmDialog];
                if (["title", "msg", "body", "yes", "no"].indexOf(propNameConfirmDialog) !== -1) {
                  thisValue = gearJS.prepareConfirmText($ele, thisValue);
                }
                if (propNameConfirmDialog === "time") {
                  propNameConfirmDialog = "timer";
                }
                if (propNameConfirmDialog === "yesClass") {
                  propNameConfirmDialog = "yesType";
                }
                options[propNameConfirmDialog] = thisValue;
              }
            }
          }
          // response._gear_confirmDialog
          promptInput(preparedPrompts, options);

          // END
          // END
          // END
          // END

          return;
        }

        gearJS.handleSuccess($ele, response, loadingToast);
        if (removalWorkaround) {
          $ele.remove();
        }
      }, error   : function(response) {
        gearJS.handleError($ele, response, loadingToast);
        if (removalWorkaround) {
          $ele.remove();
        }
      }
    });
    gearJS.activeAjax[gearName].push(ajaxRequest);
  },
  checkDisableEvents         : function($ele, events) {
    return gearJS.addEvent($ele, events, function() {
      gearJS.checkEnabledStatus($ele);
    });

  },
  hideErrorEvents            : function($ele, events) {
    return gearJS.addEvent($ele, events, function() {
      gearJS.hideErrors($ele);
    });
  },
  addEvent                   : function($ele, events, callback) {
    var hasAnEvent = false;
    if (typeof events === "string") {
      try {
        events = JSON.parse(events);
      }
      catch (e) {
        return false;
      }
    }
    if (typeof events === "undefined") {
      return false;
    }
    if (typeof events.event === "undefined") {
      events.forEach(function(event) {
        if (gearJS.addEvent($ele, event, callback)) {
          hasAnEvent = true;
        }
      });
      return hasAnEvent;
    }
    var thisDataGrid;
    if (typeof events.dataGrid !== "undefined" && typeof dataGridHelper !== "undefined") {
      thisDataGrid = gearJS.element(events.dataGrid, $ele).dataGrid();
    }
    switch (events.event) {
      case "_dataGridCheck":
        if (typeof dataGridHelper === "undefined") {
          break;
        }
        if (thisDataGrid !== null) {
          hasAnEvent = true;
          dataGridHelper.addAdditionalBinding(thisDataGrid, {
            element: ".data-grid-checkbox-row", trigger: "change", callback: function() {
              callback();
            }
          });
        }
        break;
      case "_dataGridDraw":
        if (typeof dataGridHelper === "undefined") {
          break;
        }
        if (thisDataGrid !== null && typeof thisDataGrid.onDraw !== "undefined") {
          hasAnEvent = true;
          thisDataGrid.onDraw(function() {
            callback();
          });
        }
        break;
      case "_dataGridOpen":
        if (typeof dataGridHelper === "undefined") {
          break;
        }
        if (!$ele.closest(".data-grid-open").length) {
          break;
        }
        if (thisDataGrid !== null && typeof thisDataGrid.onOpen !== "undefined") {
          hasAnEvent = true;
          thisDataGrid.onOpen(function() {
            callback();
          });
        }
        break;
      default:
        hasAnEvent = true;

        if (typeof events.bindTo === "undefined" || events.bindTo === "self") {
          var $eventElement = gearJS.element(events.element, $ele);
          $eventElement.on(events.event, function() {
            callback();
          });
        }
        else {
          var $bindElement = gearJS.element(events.bindTo, $ele);
          $bindElement.on(events.event, events.element, function() {
            callback();
          });
        }

        break;
    }
    return hasAnEvent;

  },
  startConditions            : function($ele, skipActions) {
    skipActions         = skipActions || false;
    var startConditions = $ele.gearJsAttr("data-gear-start-conditions");
    if (typeof startConditions === "undefined") {
      return true;
    }
    if (typeof startConditions === "string") {
      try {
        startConditions = JSON.parse(startConditions);
      }
      catch (e) {
        return false;
      }
    }
    var actionOnFalse = true;
    if (skipActions) {
      actionOnFalse = "noAction";
    }

    return gearJS.checkConditions(startConditions, $ele, actionOnFalse);
  },
  saveGearAttributes         : function(ele) {
    var gearID = $(ele).data("gear-js-id");
    if (typeof gearID !== "undefined" && gearID > 0) {
      return true;
    }
    var exportedName = $(ele).attr("data-gear-exported-name");
    var attributes   = {};
    if (typeof exportedName !== "undefined" && exportedName.length > 1) {
      attributes = _gear_exported_vars[exportedName];
    }

    // even if we already had the attributes loaded, overload anything that appears directly as an attribute.
    $.each(ele.attributes, function() {
      if (this.specified && this.name.substring(0, 10) === "data-gear-") {
        attributes[this.name.substring(10)] = this.value;
      }
    });
    attributes.$ele = $(ele);
    gearJS.allGears.push(attributes);
    $(ele).data("gear-js-id", gearJS.allGears.length);

    // remove the attributes, we don't need them anymore...
    if (attributes["remove-attr"] !== "no") {
      attributes["exported-name"] = exportedName;
      // no need to do this right away, it's not absolutely required anyway.... (just makes the HTML cleaner)
      setTimeout(function() {
        gearJS.removeGearAttributes($(ele), attributes);
      }, 1000);
    }
  },
  removeGearAttributes       : function($ele, attributesToRemove) {
    for (var attrName in attributesToRemove) {
      if (attributesToRemove.hasOwnProperty(attrName)) {
        $ele.removeAttr("data-gear-" + attrName);
      }
    }
  },
  init                       : function($ele) {
    $ele.each(function() {
      var valueAttr = $(this).attr("value");
      if (typeof valueAttr !== "undefined" && valueAttr !== null && valueAttr.length > 0) {
        $(this).val(valueAttr).change();
      }
      gearJS.saveGearAttributes(this);
      // check if the attributes have been saved to "allGears"
      // if not saved yet, save them
      // Remove the unneeded attributes (but not right away, give it some breathing time)
      // allow an option to skip removing attributes
      var $ele    = $(this);
      var trigger = $ele.gearJsAttr("data-gear-trigger");
      if (typeof trigger === "undefined" || trigger.length < 1) {
        trigger = "click";
      }
      if (gearJS.checkDisableEvents($ele, $ele.gearJsAttr("data-gear-check-disable-events"))) {
        gearJS.checkEnabledStatus($ele);
      }
      gearJS.hideErrorEvents($ele, $ele.gearJsAttr("data-gear-hide-error-events"));
      if (trigger === "submit" && $ele.is("form")) {
        gearJS.initSubmitButtons($ele);
      }
      $ele.off(trigger);
      $ele.on(trigger, function(e) {
        if ($ele.hasClass("disabled")) {
          e.preventDefault();
          return true;
        }
        if (!gearJS.startConditions($ele)) {
          e.preventDefault();
          return;
        }

        if ($ele.gearJsAttr("data-gear-confirm") === "yes") {
          gearJS.confirm($ele, function() {
            gearJS.triggerFunction($ele);
          });
        }
        else {
          gearJS.triggerFunction($ele);
        }


        e.preventDefault();
      });
      if (trigger === "gearJS_immediate") {
        $ele.trigger(trigger);
      }
      if ($ele.gearJsAttr("run-on-load") === "yes") {
        gearJS.triggerFunction($ele);
      }
      var runEvery = parseInt($ele.gearJsAttr("run-every"));
      if (runEvery !== 0) {
        var interval = setInterval(function() {
          if (!$ele.parents("html").length) {
            clearInterval($ele.data("runEveryInterval"));
            return true;
          }
          gearJS.triggerFunction($ele);
        }, runEvery);
        $ele.data("runEveryInterval", interval);
      }
    });

  },
  errorDisp                  : function($ele, message, fieldName, errorToastAlways, icon, time, type) {
    var identifier       = $ele.gearJsAttr("data-gear-identifier");
    var identifierClause = "";
    if (typeof identifier !== "undefined" && identifier.length > 1) {
      identifierClause = "[data-gear-identifier='" + identifier + "']";
    }
    var gearName      = $ele.gearJsAttr("data-gear-name");
    var $errorWrapper = $("[data-gear-error-name='" + gearName + "'][data-gear-error='" + fieldName + "']" + identifierClause);
    if ($errorWrapper.length) {
      $errorWrapper.html(message);
      $errorWrapper.show();
      var $errorCollapse = $errorWrapper.closest(".collapse");
      if ($errorCollapse.length) {
        $errorCollapse.collapse("show");
      }
      if (!errorToastAlways) {
        return true;
      }
    }
    if (fieldName !== "_gear_generic" || $ele.gearJsAttr("data-gear-validation-error-toast") === "yes") {
      toast({message: message, type: type, icon: icon, time: time});
    }
  },
  runActions                 : function(response, loadingToast, $triggerElement) {
    if (typeof response === "undefined" || response === null) {
      return false;
    }
    if (typeof response === "string") {
      try {
        response = JSON.parse(response);
      }
      catch (e) {
        return false;
      }
    }
    var toastUpdated = false;
    if (typeof response._notact !== "undefined" && response._notact === true) {
      gearJS.checkConditions(response, $triggerElement);
      return true;
    }
    if (typeof response.action === "undefined" || response.action.length < 1) {
      response.forEach(function(singleResponse) {
        if (typeof singleResponse._notact !== "undefined" && singleResponse._notact === true) {
          gearJS.checkConditions(singleResponse, $triggerElement);
          return true;
        }
        var result;
        if (typeof singleResponse.action === "undefined" || singleResponse.action.length < 1) {
          result = gearJS.runActions(singleResponse, loadingToast, $triggerElement);
        }
        else {
          result = gearJS.runSingleAction(singleResponse, loadingToast, $triggerElement);
        }

        if (result === true) {
          toastUpdated = true;
        }
        if (result !== true && result !== false) {
          $triggerElement = result;
        }
      });
      return toastUpdated;
    }
    return gearJS.runSingleAction(response, loadingToast, $triggerElement);
  },
  runSingleAction            : function(response, loadingToast, $triggerElement) {
    if (typeof response._notact !== "undefined" && response._notact === true) {
      gearJS.checkConditions(response, $triggerElement);
      return true;
    }
    var action       = response.action;
    var toastUpdated = false;
    var $element;
    var $insertElement;
    var thisDataGrid = false;
    if (typeof response.dataGrid !== "undefined" && typeof dataGridHelper !== "undefined") {
      thisDataGrid = gearJS.element(response.dataGrid, $triggerElement).dataGrid();
    }
    switch (action) {
      case "copy_text":
        if (typeof response.text !== "undefined") {
          gearJS.copyToClipboard(response.text)
        }
        else {
          console.log("Error, no text to copy");
        }
        break;
      case "update_toast":
        if (loadingToast !== null) {
          loadingToast.set(response);
        }
        toastUpdated = true;
        break;
      case "addToPrompt":
        var inputToAdd             = {};
        inputToAdd[response.field] = response.input;
        modalJs.addInputs(inputToAdd, response.allowDuplicates);
        break;
      case "validationError":
        var errorToastIcon   = $triggerElement.gearJsAttr("data-gear-error-toast-icon");
        var errorToastType   = $triggerElement.gearJsAttr("data-gear-error-toast-type");
        var errorToastAlways = $triggerElement.gearJsAttr("data-gear-error-toast-always") === "yes";
        var errorTime        = $triggerElement.gearJsAttr("data-gear-error-toast-time");
        if ($.isArray(response.message)) {
          response.message.forEach(function(errorSingleMessage) {
            gearJS.errorDisp($triggerElement, errorSingleMessage, response.field, errorToastAlways, errorToastIcon, errorTime, errorToastType);
          });
        }
        else {
          gearJS.errorDisp($triggerElement, response.message, response.field, errorToastAlways, errorToastIcon, errorTime, errorToastType);
        }
        break;
      case "update_or_toast":
        if (loadingToast !== null) {
          loadingToast.set(response);
        }
        else {
          toast(response);
        }
        toastUpdated = true;
        break;
      case "update_url":
        if (response.update_history === "yes") {
          window.history.pushState('', '', response.new_url);
        }
        else {
          window.history.replaceState("", "", response.new_url);
        }
        break;
      case "url_add_param":
        var newUrlAdded = gearJS.urlParam.add(response.param_name, response.value);
        if (response.update_history === "yes") {
          window.history.pushState('', '', newUrlAdded);
        }
        else {
          window.history.replaceState("", "", newUrlAdded);
        }
        break;
      case "url_remove_param":
        var newUrlRemoved = gearJS.urlParam.remove(response.param_name);
        if (response.update_history === "yes") {
          window.history.pushState('', '', newUrlRemoved);
        }
        else {
          window.history.replaceState("", "", newUrlRemoved);
        }
        break;
      case "toast":
        toast(response);
        break;
      case "console":
        console.log(response.content);
        break;
      case "data_grid_row":
        var gearName = $triggerElement.gearJsAttr("data-gear-name");
        var $newRow  = thisDataGrid.replaceRow(response.rowID, response.content);
        if ($newRow !== false) {
          if (typeof gearName === "string" && gearName.length > 0) {
            var $findGear = $newRow.find("[data-gear-name='" + gearName + "']");
            if ($findGear.length === 1) {
              return $findGear;
            }
          }
          return $newRow;
        }

        break;
      case "data_grid_detail":
        if (response.rowID === "_trigger") {
          response.rowID = $triggerElement.closest(".data-grid-tbody").attr("data-row-id");
        }

        thisDataGrid.addRowDetail(response.rowID, response.detail, response.rowClass, response.removePreviousDetail);
        break;
      case "data_grid_reload":
        var pageNum = 1;
        if (response.dataGridKeepPage === "yes") {
          pageNum = -1; // use current page number when reloading grid
        }
        thisDataGrid.search(pageNum, response.dataGridMaintainScroll === "yes");
        break;
      case "data_grid_uncheck":
        var $allCheckboxes = thisDataGrid.wrapper.find(".data-grid-checkbox-row");
        $allCheckboxes.prop("checked", false);

        $($allCheckboxes[0]).change();
        break;
      case "data_grid_close_row":
        thisDataGrid.closeRow();
        break;
      case "data_grid_open_row":
        if (response.rowID === "_trigger") {
          response.rowID = $triggerElement.closest(".data-grid-tbody").attr("data-row-id");
        }
        thisDataGrid.openRowAfterLoading(response.rowID);
        break;
      case "data_grid_open_row_if_open":
        if (thisDataGrid.rowOpen === false) {
          break;
        }
        if (response.rowID === "_trigger") {
          response.rowID = $triggerElement.closest(".data-grid-tbody").attr("data-row-id");
        }
        thisDataGrid.openRowAfterLoading(response.rowID, null, false, true);
        break;
      case "dropdown_init":
        $element = gearJS.element(response.element, $triggerElement);
        $element.dropdown("dispose");
        $element.dropdown();
        break;
      case "modal":
        $element = gearJS.element(response.element, $triggerElement);
        if (!$element.hasClass("modal")) {
          $element = $element.closest(".modal");
        }
        if (response.immediate === true) {
          $element.removeClass("fade");
        }
        $element.modal(response.function);
        if (response.immediate === true) {
          $element.addClass("fade");
        }
        break;
      case "hide":
        $element = gearJS.element(response.element, $triggerElement);
        $element.hide();
        break;
      case "show":
        $element = gearJS.element(response.element, $triggerElement);
        $element.show();
        break;
      case "toggle":
        $element = gearJS.element(response.element, $triggerElement);
        $element.toggle();
        break;
      case "setAttr":
        $element = gearJS.element(response.element, $triggerElement);
        $element.attr(response.attr, response.value);
        break;
      case "addProp":
        $element = gearJS.element(response.element, $triggerElement);
        $element.prop(response.prop, true);
        break;
      case "removeProp":
        $element = gearJS.element(response.element, $triggerElement);
        $element.prop(response.prop, false);
        break;
      case "trigger":
        $element = gearJS.element(response.element, $triggerElement);
        $element.trigger(response.event);
        break;
      case "remove":
        $element = gearJS.element(response.element, $triggerElement);
        $element.remove();
        break;
      case "focus":
        $element = gearJS.element(response.element, $triggerElement);
        $element.focus();
        break;
      case "refresh":
        if (typeof response.delaySeconds === "undefined" || response.delaySeconds === null || parseInt(response.delaySeconds) < 1) {
          location.reload();
        }
        else {
          window.setTimeout(function() {
            location.reload();
          }, parseInt(response.delaySeconds) * 1000);
        }
        break;
      case "redirect":
        location.replace(response.url);
        break;
      case "open_window":
        window.open(response.url, '_blank').focus();
        break;
      case "stop_interval":
        var interval = $triggerElement.data("runEveryInterval");
        if (typeof interval !== "undefined") {
          clearInterval(interval);
        }
        break;
      case "removeClass":
        $element = gearJS.element(response.element, $triggerElement);
        $element.removeClass(response.class);
        break;
      case "addClass":
        $element = gearJS.element(response.element, $triggerElement);
        $element.addClass(response.class);
        break;
      case "setValue":
        $element = gearJS.element(response.element, $triggerElement);
        $element.val(response.value);
        break;
      case "check":
        $element = gearJS.element(response.element, $triggerElement);
        $element.prop("checked", true);
        break;
      case "css":
        var cssProperties = JSON.parse(response.cssProperties);
        $element          = gearJS.element(response.element, $triggerElement);
        $element.css(cssProperties);
        break;
      case "uncheck":
        $element = gearJS.element(response.element, $triggerElement);
        $element.prop("checked", false);
        break;
      case "javascript":
        eval(response.content);
        break;
      case "customFunction":
        window[response.function](gearJS.element(response.element, $triggerElement));
        break;
      case "insertBefore":
        $element = gearJS.element(response.element, $triggerElement);
        if (typeof response.insertElement !== "undefined" && response.insertElement !== null) {
          $insertElement = gearJS.element(response.insertElement, $triggerElement);
          $insertElement.insertBefore($element);
        }
        else {
          $insertElement = response.insertHtml;
          $element.before($insertElement);
        }
        gearJS.newlyAddedHtml($element.parent());
        break;
      case "insertAfter":
        $element = gearJS.element(response.element, $triggerElement);
        if (typeof response.insertElement !== "undefined" && response.insertElement !== null) {
          $insertElement = gearJS.element(response.insertElement, $triggerElement);
          $insertElement.insertAfter($element);
        }
        else {
          $insertElement = response.insertHtml;
          $element.after($insertElement);
        }
        gearJS.newlyAddedHtml($element.parent());
        break;
      case "html":
        $element = gearJS.element(response.element, $triggerElement);
        if (typeof response.useIframe !== "undefined" && response.useIframe === true) {
          if (typeof iFrameDom === "undefined" || typeof iFrameDom.include !== "function") {
            console.log("iFrameDom function not found. Install 'skeets.iframe-dom' through npm.");
          }
          else {
            if (typeof response.iFrameOptions === "undefined" || response.iFrameOptions === null) {
              response.iFrameOptions = {};
            }
            iFrameDom.include($element, response.content, response.iFrameOptions, response.mode);
          }
        }
        else {
          switch (response.mode) {
            case "append":
              $element.append(response.content);
              break;
            case "prepend":
              $element.prepend(response.content);
              break;
            case "replace":
            default:
              $element.html(response.content);
              break;
          }
        }
        var $bootstrapTooltips = $element.find("[data-toggle='tooltip']");
        if ($bootstrapTooltips.length) {
          $bootstrapTooltips.tooltip();
        }
        gearJS.newlyAddedHtml($element);
        break;
      case "text":
        $element = gearJS.element(response.element, $triggerElement);
        switch (response.mode) {
          case "append":
            $element.append(response.content.replace(/&/g, "&amp;")
                                    .replace(/</g, "&lt;")
                                    .replace(/>/g, "&gt;")
                                    .replace(/"/g, "&quot;")
                                    .replace(/'/g, "&#039;"));
            break;
          case "prepend":
            $element.prepend(response.content.replace(/&/g, "&amp;")
                                     .replace(/</g, "&lt;")
                                     .replace(/>/g, "&gt;")
                                     .replace(/"/g, "&quot;")
                                     .replace(/'/g, "&#039;"));
            break;
          case "replace":
          default:
            $element.text(response.content);
            break;
        }
        break;
    }

    return toastUpdated;
  },
  gatherData                 : function($triggerElement) {
    gearJS.promptNeeded = [];
    var sources         = $triggerElement.gearJsAttr("data-gear-sources");
    return gearJS.gatherDataInner($triggerElement, sources);
  },
  gatherDataInner            : function($triggerElement, sources) {
    if (typeof sources === "undefined" || sources.length < 1) {
      return {};
    }
    if (typeof sources === "string") {
      try {
        sources = JSON.parse(sources);
      }
      catch (e) {
        console.log("Error, unable to parse sources");
        return {};
      }
    }
    var data = {};
    for (var i = 0; i < sources.length; i++) {
      var source = sources[i];
      gearJS.gatherSingleSource(data, source, $triggerElement);
      var sourceArray = gearJS.objToArray(data[source.fieldName]);
      if (source.glue !== false && Array.isArray(sourceArray)) {
        var result = "";
        var first  = true;
        sourceArray.forEach(function(pieceOfResult) {
          if (!first) {
            result += source.glue;
          }
          if (typeof pieceOfResult === "object" && typeof pieceOfResult.value !== "undefined") {
            result += pieceOfResult.value;
          }
          else {
            result += pieceOfResult;
          }
          first = false;
        });
        data[source.fieldName] = result;
      }
    }

    return data;
  },
  gatherFromForm             : function(data, $element) {
    if ($element === false) {
      return true;
    }

    var formData               = new FormData($element[0]);
    var additionalData         = gearJS.formDataToObject(formData);
    var skipDatagridSortInputs = $element.gearJsAttr("data-gear-skip-datagrid-inputs") === "yes";
    for (var property in additionalData) {
      if (additionalData.hasOwnProperty(property)) {
        if (skipDatagridSortInputs && property.lastIndexOf("sort-") === 0) {
          continue;
        }
        if (skipDatagridSortInputs && property.lastIndexOf("dataGridSessionInput") === 0) {
          continue;
        }
        data[property] = additionalData[property];
      }
    }
    var $clickedSubmitButton = $element.find("input[type=submit][data-gear-clicked-submit='yes'], button[data-gear-clicked-submit='yes']");
    if ($clickedSubmitButton.length === 1) {
      var buttonName = $clickedSubmitButton.attr("name");
      if (typeof buttonName !== "undefined" && buttonName.length > 0) {
        data[buttonName] = $clickedSubmitButton.val();
      }
    }
    $clickedSubmitButton.attr("data-gear-clicked-submit", "no");
  },
  gatherForDataGridQuery     : function(data, $element) {
    if ($element === false) {
      return true;
    }

    data["_dataGridGlobalInput"] = $element.dataGrid().gatherVars();
  },
  checkSingleCondition       : function(condition, $triggerElement, actionOnFalse) {
    var output            = true;
    var $ConditionElement = false;
    var thisDataGrid      = false;
    if (typeof condition.element !== "undefined") {
      $ConditionElement = gearJS.element(condition.element, $triggerElement);
    }
    if (typeof condition.dataGrid !== "undefined" && typeof dataGridHelper !== "undefined") {
      thisDataGrid = gearJS.element(condition.dataGrid, $triggerElement).dataGrid();
    }
    switch (condition.type) {
      case "exists":
        if ($ConditionElement === false || !$ConditionElement.length) {
          output = false;
        }
        break;
      case "checked":
        if ($ConditionElement === false || !$ConditionElement.length || !$ConditionElement.is(":checked")) {
          output = false;
        }
        break;
      case "unchecked":
        if ($ConditionElement === false || !$ConditionElement.length || $ConditionElement.is(":checked")) {
          output = false;
        }
        break;
      case "is":
        if ($ConditionElement === false || !$ConditionElement.length || !$ConditionElement.is(condition.is)) {
          output = false;
        }
        break;
      case "dataGridOneChecked":
        if (thisDataGrid === false || thisDataGrid.checkedRows().length < 1) {
          output = false;
        }
        break;
      case "dataGrid":
        if (thisDataGrid === false) {
          output = false;
        }
        var $row;
        if (condition.rowID === "_trigger") {
          $row = dataGridHelper.getRowFromElement($triggerElement);
          if (!($row instanceof jQuery)) {
            break;
          }
        }
        else {
          $row = thisDataGrid.rowElem(condition.rowID);
        }


        var value = dataGridHelper.getRowDataValue($row, condition.columnName);
        if ($.isArray(condition.value)) {
          if ($.inArray(value, condition.value) === -1) {
            output = false;
          }
        }
        else {
          if (value !== condition.value) {
            output = false;
          }
        }

        break;
      case "dataGridNotEmpty":
        if (thisDataGrid === false) {
          output = false;
        }
        var $row;
        if (condition.rowID === "_trigger") {
          $row = dataGridHelper.getRowFromElement($triggerElement);
          if (!($row instanceof jQuery)) {
            break;
          }
        }
        else {
          $row = thisDataGrid.rowElem(condition.rowID);
        }


        var value = dataGridHelper.getRowDataValue($row, condition.columnName);

        if (typeof value === "undefined" || value.length < 1) {
          output = false;
        }
        break;
      case "dataGridOneCheckedValue":
        if (thisDataGrid === false || thisDataGrid.checkedRowsWithValue(condition.field, condition.value).length < 1) {
          output = false;
        }
        break;
      case "dataGridAllCheckedHaveValue":
        if (thisDataGrid ===
            false ||
            thisDataGrid.checkedRowsWithValue(condition.field, condition.value).length !==
            thisDataGrid.checkedRows().length) {
          output = false;
        }
        break;
      case "or":
        if (!gearJS.checkConditions(condition.conditions, $triggerElement, actionOnFalse, true)) {
          output = false;
        }
        break;
      case "dataGridNotOneChecked":
        if (thisDataGrid === false || thisDataGrid.checkedRows().length > 0) {
          output = false;
        }
        break;
      case "dataGridNotOneCheckedValue":
        if (thisDataGrid === false || thisDataGrid.checkedRowsWithValue(condition.field, condition.value).length > 0) {
          output = false;
        }
        break;
      case "attr":
        if ($.isArray(condition.value)) {
          if ($ConditionElement === false || $.inArray($ConditionElement.attr(condition.attr), condition.value) === -1) {
            output = false;
          }
        }
        else {
          if ($ConditionElement === false || $ConditionElement.attr(condition.attr) !== condition.value) {
            output = false;
          }
        }
        break;
      case "val":
        if ($.isArray(condition.value)) {
          if ($ConditionElement === false || $.inArray($ConditionElement.val(), condition.value) === -1) {
            output = false;
          }
        }
        else {
          if ($ConditionElement === false || $ConditionElement.val() !== condition.value) {
            output = false;
          }
        }

        break;
      case "not_val":
        output = false;
        if ($.isArray(condition.value)) {
          if ($ConditionElement === false || $.inArray($ConditionElement.val(), condition.value) === -1) {
            output = true;
          }
        }
        else {
          if ($ConditionElement === false || $ConditionElement.val() !== condition.value) {
            output = true;
          }
        }

        break;
    }
    if (typeof condition.invert !== "undefined" && condition.invert === true) {
      output = !output;
    }
    if (actionOnFalse !== "noAction") {
      if (((actionOnFalse && output === false) || (!actionOnFalse && output === true)) && typeof condition.action !== "undefined") {
        gearJS.runActions(condition.action, null, $triggerElement);
      }
    }
    return output;
  },
  checkConditions            : function(conditions, $triggerElement, actionOnFalse, orBinding) {
    actionOnFalse = actionOnFalse || false;
    orBinding     = orBinding || false;
    var thisActionOnFalse;
    var output    = true;
    if (orBinding) {
      output = false;
    }
    if (typeof conditions.type !== "undefined" && conditions.type.length > 0) {
      conditions = [conditions];
    }
    for (var j = 0; j < conditions.length; j++) {
      var condition     = conditions[j];
      thisActionOnFalse = actionOnFalse;
      if (condition.actionOnFalse !== "default") {
        thisActionOnFalse = condition.actionOnFalse;
      }
      if (gearJS.checkSingleCondition(condition, $triggerElement, thisActionOnFalse)) {
        if (orBinding) {
          output = true;
        }
      }
      else {
        if (!orBinding) {
          output = false;
        }
      }
    }
    return output;
  },
  gatherSingleSource         : function(data, source, $triggerElement) {
    var eachElement = false;
    var $eachElement;
    if (source.prompt) {
      if (typeof source.fieldName === "undefined" || source.fieldName.length < 1) {
        return true;
      }
      source.prompt = false;
      var tmpData   = {};
      this.gatherSingleSource(tmpData, source, $triggerElement);
      source.prompt             = true;
      source.defaultPromptValue = tmpData[source.fieldName];
      gearJS.promptNeeded.push(source);
      return true;
    }
    if (typeof source.eachElement !== "undefined" && source.eachElement !== null) {
      eachElement  = true;
      $eachElement = gearJS.element(source.eachElement, $triggerElement);
    }

    if (!gearJS.checkConditions(source.conditions, $triggerElement, true)) {
      return true;
    }
    if (eachElement) {
      if ($eachElement === false) {
        return true;
      }
      $eachElement.each(function() {
        gearJS.gatherAfterVerify(data, source, $triggerElement, $(this));
      });
    }
    else {
      gearJS.gatherAfterVerify(data, source, $triggerElement);
    }
  },

  gatherAfterVerify: function(data, source, $triggerElement, $eachElement) {
    var $element;
    if (typeof source.element === "undefined" || source.element === null) {
      $element = $triggerElement;
    }
    else {
      $element = gearJS.element(source.element, $triggerElement, $eachElement);
    }
    var returnArray = source.array || false;
    for (var k = 0; k < source.eachConditions.length; k++) {
      var condition         = source.eachConditions[k];
      var $ConditionElement = gearJS.element(condition.element, $triggerElement, $eachElement);
      if ($ConditionElement === false || !$ConditionElement.length || !$ConditionElement.is(condition.is)) {
        return true;
      }
    }

    if (typeof source.form !== "undefined" && source.form === true) {
      gearJS.gatherFromForm(data, $element);
      return true;
    }
    if (typeof source.fieldName === "undefined" || source.fieldName.length < 1) {
      return true;
    }
    var field = source.fieldName;

    if (typeof source.js !== "undefined" && source.js.length > 0) {
      gearJS.addToData(data, field, eval(source.js));
      return true;
    }

    if (typeof source.elementData !== "undefined" && source.elementData.length > 0) {
      var returnValue = $element.data(source.elementData);

      if (typeof source.elementDataSub !== "undefined" && source.elementDataSub.length > 0) {
        returnValue = returnValue[source.elementDataSub];
      }
      gearJS.addToData(data, field, returnValue, returnArray);
      return true;
    }

    if (typeof source.dataGridQuery !== "undefined" && source.dataGridQuery === true) {
      gearJS.gatherForDataGridQuery(data, $element);
      gearJS.addToData(data, field, "_dataGridQuery", returnArray);
      return true;
    }

    if (typeof source.value !== "undefined" && source.value !== null) {
      gearJS.addToData(data, field, source.value, returnArray);
      return true;
    }
    if (typeof source.fromUrl !== "undefined" && source.fromUrl === true) {
      if (typeof source.urlParam !== "undefined" && source.urlParam.length > 0) {
        var urlParams = new URLSearchParams(window.location.search);

        gearJS.addToData(data, field, urlParams.get(source.urlParam), returnArray);
        return true;
      }
      gearJS.addToData(data, field, window.location.href, returnArray);
      return true;
    }
    if (typeof source.fromDataGrid !== "undefined" && source.fromDataGrid === true) {

      gearJS.gatherFromDataGridColumn(data, source, field, $element, returnArray);
      return true;
    }

    if (typeof $element === "undefined" || $element === null || $element === false) {
      gearJS.addToData(data, field, "", returnArray);
      return true;
    }

    gearJS.gatherFromAttribute(data, source, field, $element, returnArray);
  },

  gatherFromDataGridColumn: function(data, source, field, $element, returnArray) {
    var output = "";
    if (typeof dataGridHelper === "undefined") {
      gearJS.addToData(data, field, output, returnArray);
      return true;
    }

    if (typeof source.attribute === "undefined" || source.attribute.length < 1) {
      gearJS.addToData(data, field, output, returnArray);
      return true;
    }
    var $dataGridRow;
    if (source.dataGridFirstRow === "yes") {
      $dataGridRow = $element.dataGrid().getRowNumElem(1);
    }
    else {
      if (source.dataGridFirstRow === "checked") {
        $dataGridRow = $element.dataGrid().getRowNumElem(1, true);
      }
      else {
        $dataGridRow = dataGridHelper.getRowFromElement($element);
      }
    }
    var rowID = $dataGridRow.attr("data-row-id");
    if (source.attribute === "row_id") {
      gearJS.addToData(data, field, rowID, returnArray);
      return true;
    }
    output = dataGridHelper.getRowDataValue($dataGridRow, source.attribute);
    if (source.dataGridColValOnly) {
      gearJS.addToData(data, field, output, returnArray);
    }
    else {
      gearJS.addToData(data, field, output, returnArray, rowID);
    }

    return true;
  },

  gatherFromAttribute: function(data, source, field, $element, returnArray) {
    var attribute;

    if (typeof source.attribute === "undefined" || source.attribute.length < 1) {
      attribute = "val";
    }
    else {
      attribute = source.attribute;
    }
    switch (attribute) {

      case "text":
      case "_text":
        gearJS.addToData(data, field, $element.text().trim(), returnArray);
        break;
      case "html":
      case "_html":
        gearJS.addToData(data, field, $element.html().trim(), returnArray);
        break;
      case "val":
        gearJS.addToData(data, field, $element.val(), returnArray);
        break;
      case "checked":
        gearJS.addToData(data, field, $element.is(":checked"), returnArray);
        break;
      case "unchecked":
        gearJS.addToData(data, field, !$element.is(":checked"), returnArray);
        break;
      default:
        gearJS.addToData(data, field, $element.attr(attribute), returnArray);
        break;

    }
    if (attribute === "val") {

    }
    else {

    }
  },

  addToData              : function(data, field, value, isArray, arrayIndex) {
    if (isArray) {
      if (typeof data[field] === "undefined") {
        if (typeof arrayIndex === "undefined") {
          data[field] = [];
        }
        else {
          data[field] = {};
        }
      }
      if (typeof arrayIndex === "undefined") {
        data[field].push(value);
        return;
      }
      if (typeof arrayIndex !== "undefined" && typeof data[field][arrayIndex] === "undefined") {
        data[field][arrayIndex] = value;
      }
      else {
        console.log("Warning.... duplicate array indexes found. Is this in a datagrid with multiple rows with the same row_id?");
        gearJS.addToData(data, field, value, isArray, "_" + arrayIndex)
      }

    }
    else {
      data[field] = value;
    }
  },
  element                : function(elementObj, $triggerElement, $eachElement) {
    if (typeof elementObj === "string") {
      elementObj = JSON.parse(elementObj);
    }
    var $output  = null;
    var relative = "global";
    var selector;
    elementObj.trace.forEach(function(thisTrace) {
      selector = thisTrace[0];

      if (selector === "this") {
        if (typeof $eachElement !== "undefined" && $eachElement.length) {
          selector = $eachElement;
        }
        else {
          selector = $triggerElement;
        }
      }
      if (selector === "trigger" || selector === "_trigger") {
        selector = $triggerElement;
      }
      if ($output === null) {
        if (selector instanceof jQuery) {
          $output = selector;
        }
        else {
          $output = $(selector);
        }

      }
      else {
        switch (relative) {
          case "find":
            $output = $output.find(selector);
            break;
          case "closest":
            $output = $output.closest(selector);
            break;
          default:
            $output = false;
            return false;
        }
      }
      relative = thisTrace[1];

    });
    if ($output !== null && ($output === false || !$output.length)) {
      return $output;
    }


    selector = elementObj.selector;
    if (selector === "this") {
      if (typeof $eachElement !== "undefined" && $eachElement.length) {
        selector = $eachElement;
      }
      else {
        selector = $triggerElement;
      }

    }
    if (selector === "trigger" || selector === "_trigger") {
      selector = $triggerElement;
    }
    if ($output === null) {
      relative = "no";
    }
    switch (relative) {
      case "find":
        if ($output !== null) {
          $output = $output.find(selector);
        }
        break;
      case "closest":
        if ($output !== null) {
          $output = $output.closest(selector);
        }
        break;
      default:
        $output = $(selector);
        break;

    }

    return $output;

  },
  serializedArrayToObject: function(array) {
    var output = {};
    array.forEach(function(element) {
      if (typeof output[element.name] === "undefined") {
        output[element.name] = element.value;
      }
      else {
        if (typeof output[element.name] === "object") {
          output[element.name].push(element.value);
        }
        else {
          output[element.name] = [output[element.name], element.value];
        }

      }

    });

    return output;
  },
  formDataToObject       : function(formData) {
    var output = {};
    for (var pair of formData.entries()) {
      if (typeof output[pair[0]] === "undefined") {
        output[pair[0]] = pair[1];
      }
      else {
        if (typeof output[pair[0]] === "object") {
          output[pair[0]].push(pair[1]);
        }
        else {
          output[pair[0]] = [output[pair[0]], pair[1]];
        }

      }
    }
    return output;
  },
  checkEnabledStatus     : function($ele) {

    var whenConditionsFail      = $ele.gearJsAttr("data-gear-when-conditions-fail");
    var whenConditionsFailTitle = $ele.gearJsAttr("data-gear-when-conditions-fail-title");
    if (whenConditionsFail !== "hide") {
      whenConditionsFail = "disable";
    }

    var conditionsSuccess = gearJS.startConditions($ele, true);
    if ($ele.is("form")) {
      $ele = $ele.find("[type=submit]");
    }

    if (conditionsSuccess) {
      if (whenConditionsFail === "hide") {
        $ele.show();
      }
      else {
        $ele.removeClass("disabled");
      }
    }
    else {
      if (whenConditionsFail === "hide") {
        $ele.hide();
      }
      else {
        $ele.addClass("disabled");
      }
    }

    var $tooltipWrapper;
    if (typeof whenConditionsFailTitle !== "undefined" && whenConditionsFailTitle.length > 0) {
      if (conditionsSuccess) {
        $tooltipWrapper = $ele.closest(".gear-js-tooltip-wrapper");
        if ($tooltipWrapper.length) {
          $tooltipWrapper.tooltip("dispose");
          $tooltipWrapper.attr("title", "");
        }
      }
      else {
        // need to wrap the element first because it's disabled and has no pointer events...
        $tooltipWrapper = $ele.closest(".gear-js-tooltip-wrapper");
        if (!$tooltipWrapper.length) {
          $tooltipWrapper = $("<div>",
            {
              class: "gear-js-tooltip-wrapper",
              style: "display: inline-block;"
            });
          $ele.wrap(function() {
            return $tooltipWrapper;
          });
        }
        $tooltipWrapper = $ele.closest(".gear-js-tooltip-wrapper");
        $tooltipWrapper.tooltip("dispose");
        $tooltipWrapper.attr("title", whenConditionsFailTitle);
        $tooltipWrapper.tooltip();
      }
    }
  },
  objToArray             : function(source) {
    if (Array.isArray(source)) {
      return source;
    }
    if (typeof source !== "object") {
      return source;
    }
    var output = [];
    var key;
    for (key in source) {
      if (source.hasOwnProperty(key)) {
        output.push(source[key]);
      }
    }
    return output;
  },
  urlParam               : {
    remove: function(paramName, url) {
      url     = url || window.location.href;
      var reA = new RegExp("&" + paramName + "=[^&#]*", "g");
      var reB = new RegExp("\\?" + paramName + "=[^&#]*&", "g");
      var reC = new RegExp("\\?" + paramName + "=[^&#]*", "g");
      url     = url.replace(reA, "");
      url     = url.replace(reB, "?");
      url     = url.replace(reC, "");

      return url;
    },
    add   : function(paramName, value, url) {
      url = url || window.location.href;
      url = url.replace(/#.*$/g, "");
      url = gearJS.urlParam.remove(paramName, url);
      if (url.indexOf("?") !== -1) {
        var count = (url.match(/\?/g) || []).length;
        if (url.substr(-1) === "?" && count === 1) {
          url += paramName + "=";
        }
        else {
          url += "&" + paramName + "=";
        }
      }
      else {
        url += "?" + paramName + "=";
      }
      url += value;

      return url;
    }
  },
  convertToFormData      : function(data) {
    var form_data = new FormData();
    gearJS.appendToFormData(form_data, data);
    return form_data;
  },
  appendToFormData       : function(form_data, appendData) {
    for (var key in appendData) {
      gearJS.appendSingleToFormData(form_data, key, appendData[key]);
    }
  },
  appendSingleToFormData : function(form_data, key, value) {
    if (typeof value === "object" && !value instanceof File) {
      for (var objectKey in value) {
        form_data.append(key[objectKey], value[objectKey]);
      }
    }
    else {
      form_data.append(key, value);
    }
  },
  dataContainsFile       : function(data) {
    var containsFile = false;
    for (var fileTestKey in data) {
      if (data[fileTestKey] instanceof File) {
        containsFile = true;
        break;
      }
    }
    return containsFile;
  }
};

jQuery.fn.extend({
  gearJsAttr: function(attrName, newValue) {
    if (attrName.substring(0, 10) === "data-gear-") {
      attrName = attrName.substring(10);
    }
    var gearID = $(this).data("gear-js-id");
    if (typeof gearID === "undefined" || gearID < 1) {
      if (typeof newValue !== "undefined") {
        $(this).attr("data-gear-" + attrName, newValue);
        return $(this);
      }
      return $(this).attr("data-gear-" + attrName);
    }
    if (typeof newValue !== "undefined") {
      gearJS.allGears[gearID - 1][attrName] = newValue;
      return $(this);
    }
    return gearJS.allGears[gearID - 1][attrName];

  }
});


jQuery.fn.extend({
  gearJs: function() {
    var gearID = $(this).data("gear-js-id");
    if (typeof gearID === "undefined" || gearID < 1) {
      return false;
    }
    return gearJS.allGears[gearID - 1];

  }
});
jQuery.fn.extend({
  gearJsAuto: function() {
    gearJS.auto($(this));
  }
});


gearJS.auto();

// export {gearJS};

